export const BILIRICA_TEMPLATE = `<!doctype html>
    <html lang="en-US">
    <head>
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5">
        <title>Results - PropertyChecker</title>
                <link type="image/x-icon" href="/img/favicon.png" rel="shortcut icon">
<link href="/css/report.ksink.css?v=1.31" rel="stylesheet">
<link href="/packages/jquery.ui.1.13.2/jquery-ui.min.css" rel="stylesheet">
<link type="text/css" href="/css/dashboard.css?v=1.51" rel="stylesheet">
<link href="/css/footersoc.css" rel="stylesheet">
<link type="text/css" href="/css/custom-ma.css?v=2.0" rel="stylesheet">
<script src="/js/jquery-3.6.4.min.js"></script>
<script src="/packages/markjs/mark.js-master/dist/jquery.mark.js"></script>
<script>
        window.USER_INFO_DATA = {
            hasExpiredSearches: Boolean(\`\`)
        };
        <\/script>        <!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-MLJRRBRG');</script>
<!-- End Google Tag Manager -->
    </head>
    <body id="top" class="members">
        <!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MLJRRBRG"
                  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->
        <a class="skip-to-content-link" href="#main" title="skip">Skip to content</a>
        <a class="gototop smooth" href="#top" title="Go to top"></a>
                <header class="site-header">
    <div class="wrapper">
        <div class="row">
            <div class="site-logo ">
                <a href="/members" title="Home Page">PropertyChecker.com</a>
            </div>
            <nav id="menu" class="hnav">
                <ul>
                                            <li>
                            <a class="search-again" href="javascript:void(0)"
                               onclick="searchAgainShow('property')"
                               title="Search Again">Search Again</a>
                        </li>
                                        <li><a class="hnav-icn icn-home" href="/members" title="Home">Home</a></li>
                    <li><a class="hnav-icn icn-support" href="/members/contact" title="Support">Support</a></li>
                    <li class="has-sub">
                        <a class="hnav-icn icn-account" href="#" title="My Account">My Account</a>
                        <ul>
                            <li><a class="icn-account" href="/members/account">My Account</a></li>
                                                        <li><a class="icn-reports" href="/members/reports/my-reports">My Reports</a></li>
                            <li><a class="icn-logout" href="/logout">Sign Out</a></li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</header>

    <div id="searchAgain" class="lbox-container lbox-nohit">
    <div class="lbox-container-inn xl">
        <div class="lbox-nohit-cont">
            <h1 class="h3 center mb0">Search Property & Deed Records</h1>
            <div class="d-search tabs mt5">
                <div class="nav tnav">
                    <ul>
                        <li><a class="link" href="#tabAddress">Address</a></li>
                                                <li><a class="link" href="#tabName">Name</a></li>
                        <li><a class="link" href="#tabPhone">Phone</a></li>
                        <li><a class="link" href="#tabEmail">Email</a></li>
                                                                                                        <li><a class="link" href="#tabParcel">Parcel ID</a></li>
                                            </ul>
                </div>
                <div class="cnt">
                    <div class="tblock" id="tabAddress">
                        <form method="post" action="/members/search" class="maSearchForm" data-searchType="address">
    <div class="form address">
            <input type="hidden" name="reportType" value="all">
            <input type="hidden" name="checkoutItem" value="64c0be69947aa16db90a3103">
            <input type="hidden" name="searchSetAlias" value="premiumProperty.byAddress">
                            <div class="row">
                    <div class="col">
                        <div class="form-input autocomplete">
                            <label class="form-label" for="address">Property Address <span class="color-warning">*</span></label>
                            <input
                                class="form-control lg autocomplete" id="addresssearchform-address-67647ecbfc0f0418403ff5dd"
                                name="address"
                                type="search"
                                placeholder="e.g. 123 Main St, Apt 3,  Boston, MA"
                                data-unique-id="67647ecbfc0f0418403ff5dd"
                                data-old-value=""
                                value=""
                            >
                            <input type="hidden" id="address-json-67647ecbfc0f0418403ff5dd" name="address-json">                            <input type="hidden" id="external-id-67647ecbfc0f0418403ff5dd" name="externalId">                            <input type="hidden" id="address-true-67647ecbfc0f0418403ff5dd" name="address-true">                            <input type="hidden" name="lat">
                            <input type="hidden" name="long">
                            <div class="form-error">Please enter property address</div>

                            <div id="suggesstion-box" class="autocomplete-suggestions"></div>    <div style="display:block; opacity:0; width:0px; height:0px; position: relative; top: -24px">
        <input type="text" id="address-unit-67647ecbfc0f0418403ff5dd" name="address-unit" />
    </div>                        </div>
                    </div>
            </div>
        <div class="row">
            <div class="col cta">
                <button class="btn md arw" type="submit">Search</button>
            </div>
        </div>
    </div>
    </form>
                    </div>
                                        <div class="tblock" id="tabName">
                        <form method="post" action="/members/search" class="maSearchForm" data-searchType="name">
    <div class="form name">
            <input type="hidden" name="mercSearchTypeId" value="652561a84e31c824cc153f19">
                <div class="row">
                    <div class="col">
                        <div class="form-input">
                            <label class="form-label" for="nameFirstName">First Name <span class="color-warning">*</span></label>
                            <input class="form-control lg" id="nameFirstName"
                                   name="firstName" type="text" placeholder="e.g. John" value="">
                            <div class="form-error">Please enter first name</div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-input">
                            <label class="form-label" for="nameLastName">Last Name <span class="color-warning">*</span></label>
                            <input class="form-control lg" id="nameLastName"
                                   name="lastName" type="text" placeholder="e.g. Smith" value="">
                            <div class="form-error">Please enter last name</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-input">
                            <label class="form-label" for="nameCity">City</label>
                            <input class="form-control lg" id="nameCity"
                                   name="city" type="text" placeholder="e.g. Boston" value="">
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-input">
                            <label class="form-label" for="nameState">State <span class="color-warning">*</span></label>
                            <select class="form-control lg" id="nameState" name="state" aria-label="state">
                                <option value="" selected disabled>Select State...</option>
                                <option value="AL" >Alabama</option>
                                <option value="AK" >Alaska</option>
                                <option value="AZ" >Arizona</option>
                                <option value="AR" >Arkansas</option>
                                <option value="CA" >California</option>
                                <option value="CO" >Colorado</option>
                                <option value="CT" >Connecticut</option>
                                <option value="DE" >Delaware</option>
                                <option value="DC" >District of Columbia</option>
                                <option value="FL" >Florida</option>
                                <option value="GA" >Georgia</option>
                                <option value="HI" >Hawaii</option>
                                <option value="ID" >Idaho</option>
                                <option value="IL" >Illinois</option>
                                <option value="IN" >Indiana</option>
                                <option value="IA" >Iowa</option>
                                <option value="KS" >Kansas</option>
                                <option value="KY" >Kentucky</option>
                                <option value="LA" >Louisiana</option>
                                <option value="ME" >Maine</option>
                                <option value="MD" >Maryland</option>
                                <option value="MA" >Massachusetts</option>
                                <option value="MI" >Michigan</option>
                                <option value="MN" >Minnesota</option>
                                <option value="MS" >Mississippi</option>
                                <option value="MO" >Missouri</option>
                                <option value="MT" >Montana</option>
                                <option value="NE" >Nebraska</option>
                                <option value="NV" >Nevada</option>
                                <option value="NH" >New Hampshire</option>
                                <option value="NJ" >New Jersey</option>
                                <option value="NM" >New Mexico</option>
                                <option value="NY" >New York</option>
                                <option value="NC" >North Carolina</option>
                                <option value="ND" >North Dakota</option>
                                <option value="OH" >Ohio</option>
                                <option value="OK" >Oklahoma</option>
                                <option value="OR" >virginia</option>
                                <option value="PA" >Pennsylvania</option>
                                <option value="RI" >Rhode Island</option>
                                <option value="SC" >South Carolina</option>
                                <option value="SD" >South Dakota</option>
                                <option value="TN" >Tennessee</option>
                                <option value="TX" >Texas</option>
                                <option value="UT" >Utah</option>
                                <option value="VT" >Vermont</option>
                                <option value="VA" >Virginia</option>
                                <option value="WA" >Washington</option>
                                <option value="WV" >West Virginia</option>
                                <option value="WI" >Wisconsin</option>
                                <option value="WY" >Wyoming</option>
                            </select>
                            <div class="form-error">Please select a state</div>
                        </div>
                    </div>
        </div>
        <div class="row">
            <div class="col cta">
                <button type="submit" class="btn md arw no-scale">Search</button>            </div>
        </div>
    </div>
</form>
                    </div>
                    <div class="tblock" id="tabPhone">
                        <form method="post" action="/members/search" class="maSearchForm" data-searchType="phone">
    <div class="form phone">
            <input type="hidden" name="mercSearchTypeId" value="65258a752632d5ec885f6e75">
                        <div class="row">
                <div class="col">
                    <div class="form-input">
                        <label class="form-label" for="phoneNumber">Phone Number <span class="color-warning">*</span></label>
                        <input class="form-control lg" id="phoneNumber" name="phone" type="tel" placeholder="e.g. 555-555-5555" value="">
                        <div class="form-error">Please enter a valid phone number</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col cta">
                    <button type="submit" class="btn md arw no-scale">Search</button>                </div>
            </div>
    </div>
</form>
                    </div>
                    <div class="tblock" id="tabEmail">
                        <form method="post" action="/members/search" class="maSearchForm" data-searchType="email">
    <div class="form email">
        <input type="hidden" name="mercSearchTypeId" value="65258a71b89bbeba17c414ea">
        <input type="hidden" name="reportType" value="email">
                <div class="row">
            <div class="col">
                <div class="form-input">
                    <label class="form-label" for="emailAddress">Email Address <span class="color-warning">*</span></label>
                    <input class="form-control lg" id="emailAddress" name="email" type="email" placeholder="e.g. johnsmith@email.com" value="">
                    <div class="form-error">Please enter a valid email address</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col cta">
                <button type="submit" class="btn md arw">Search</button>            </div>
        </div>
    </div>
</form>
                    </div>
                                                                <div class="tblock" id="tabParcel">
                            <form method="post" action="/members/search" class="maSearchForm" data-searchType="parcelId">
    <input type="hidden" name="reportType" value="all">
    <input type="hidden" name="checkoutItem" value="64c0be69947aa16db90a3103">
    <input type="hidden" name="searchSetAlias" value="premiumProperty.byAddress">
        <div class="form">
        <div class="row">
            <div class="col">
                <div class="form-input">
                    <label class="form-label" for="parcelID">Parcel ID <span class="color-warning">*</span></label>
                    <input class="form-control lg capitalize" id="parcelID" name="parcelId" type="text" placeholder="e.g. 415-02914-308" value="">
                    <div class="form-error">Please enter Parcel ID</div>
                </div>
            </div>
            <div class="col state">
                <div class="form-input">
                    <label class="form-label" for="parcelState">State <span class="color-warning">*</span></label>
                    <select class="form-control lg capitalize" id="parcelState" name="state" aria-label="state">
                        <option value="" selected disabled>State</option>
                        <option value="AL" >Alabama</option>
                        <option value="AK" >Alaska</option>
                        <option value="AZ" >Arizona</option>
                        <option value="AR" >Arkansas</option>
                        <option value="CA" >California</option>
                        <option value="CO" >Colorado</option>
                        <option value="CT" >Connecticut</option>
                        <option value="DE" >Delaware</option>
                        <option value="DC" >District of Columbia</option>
                        <option value="FL" >Florida</option>
                        <option value="GA" >Georgia</option>
                        <option value="HI" >Hawaii</option>
                        <option value="ID" >Idaho</option>
                        <option value="IL" >Illinois</option>
                        <option value="IN" >Indiana</option>
                        <option value="IA" >Iowa</option>
                        <option value="KS" >Kansas</option>
                        <option value="KY" >Kentucky</option>
                        <option value="LA" >Louisiana</option>
                        <option value="ME" >Maine</option>
                        <option value="MD" >Maryland</option>
                        <option value="MA" >Massachusetts</option>
                        <option value="MI" >Michigan</option>
                        <option value="MN" >Minnesota</option>
                        <option value="MS" >Mississippi</option>
                        <option value="MO" >Missouri</option>
                        <option value="MT" >Montana</option>
                        <option value="NE" >Nebraska</option>
                        <option value="NV" >Nevada</option>
                        <option value="NH" >New Hampshire</option>
                        <option value="NJ" >New Jersey</option>
                        <option value="NM" >New Mexico</option>
                        <option value="NY" >New York</option>
                        <option value="NC" >North Carolina</option>
                        <option value="ND" >North Dakota</option>
                        <option value="OH" >Ohio</option>
                        <option value="OK" >Oklahoma</option>
                        <option value="OR" >virginia</option>
                        <option value="PA" >Pennsylvania</option>
                        <option value="RI" >Rhode Island</option>
                        <option value="SC" >South Carolina</option>
                        <option value="SD" >South Dakota</option>
                        <option value="TN" >Tennessee</option>
                        <option value="TX" >Texas</option>
                        <option value="UT" >Utah</option>
                        <option value="VT" >Vermont</option>
                        <option value="VA" >Virginia</option>
                        <option value="WA" >Washington</option>
                        <option value="WV" >West Virginia</option>
                        <option value="WI" >Wisconsin</option>
                        <option value="WY" >Wyoming</option>
                    </select>
                    <div class="form-error">Please select a state</div>
                </div>
            </div>
            <div class="col state">
                <div class="form-input">
                    <label class="form-label" for="parcelState">County <span class="color-warning">*</span></label>
                    <select class="form-control lg capitalize" id="parcelCounty" name="county" aria-label="county">
                        <option value="" selected disabled>Select County...</option>
                    </select>
                    <div class="form-error">Please select a county</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col cta">
                <button class="btn md arw no-scale" type="submit">Search</button>
            </div>
        </div>
    </div>
</form>
                        </div>
                                    </div>
            </div>
        </div>
        <a class="lbox-close" href="javascript:void(0)" onclick="searchAgainHide()" title="Close">Close</a>
    </div>
</div>
<div id="preForeclosuresSearchAgain" class="lbox-container lbox-nohit">
    <div class="lbox-container-inn xl">
        <div class="lbox-nohit-cont">
            <h1 class="h3 center mb0">Search Pre-Foreclosures</h1>
            <div class="d-search mt5">
                <div class="cnt">
                    <div class="tblock" id="tabPreForeclosures">
                        <form method="post" action="/members/reports/pre-foreclosures" data-searchType="preForeclosures">
    <input type="hidden" name="_csrf-frontend" value="pFFpNZmglCLJNAFtPmECVNFZcdxgS0vZjGzz7NEtJonKGz95oeOkcIxcSBpuD0UFmxNDpFEzZom5ILirqUZK2A==">    <div class="form zip">
        <div class="row">
            <div class="col">
                <div class="form-input">
                    <label class="form-label" for="zipForeclosures">Zip Code <span class="color-warning">*</span></label>
                    <input class="form-control lg" id="zipForeclosures" type="text"
                           placeholder="e.g. 01234" name="zip" value="" required="required">
                    <div class="form-error">Please enter zip code</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col cta">
                <button class="btn md arw" type="submit">Search</button>
            </div>
        </div>
    </div>
</form>                    </div>
                </div>
            </div>
        </div>
        <a class="lbox-close" href="javascript:void(0)" onclick="searchAgainHide('preForeclosures')" title="Close">Close</a>
    </div>
</div>
<style>
    /*Make visible over others + address searching on the same level*/
    #preForeclosuresSearchAgain,
    #searchAgain, .ui-front {
        z-index: 1000;
    }
</style>
        
<!--Error Details message-->
<!--"Too Many Results", "Insufficient information to perform search" and other messages from vendor-->
<!--	-->
<body class="members">
    <div class="site-container" id="premiumPropertyReportContent">
        <main id="main" class="site-content">
            <div class="wrapper">
                <div class="report report-property">
                                                                    <div class="r-header">
    <div class="row">
        <div class="col ttl">
                        <h1>Property Report for <span class="color-special search-for">29 Robinhood Ln, Billerica, MA 01821</span></h1>
                    </div>
        <div class="col ctr">
                            <a class="ctr-pdf" href="/reportPdf?id=6744a2dc5697ac88a00542d7&isDownloadFile=1" title="Download PDF">Download PDF</a>
                        <a class="ctr-print" href="javascript:void(0)" onclick="window.print()" title="Print Page">Print Page</a>

                        <div id="sendShareables" class="lbox-container">
                <div class="lbox-container-inn lg">
                    <div class="lbox-head">
                        <h1 class="h3 center">Email This Property</h1>
                    </div>
                    <div class="lbox-body">
                        <div class="form mw-sm mxa">
                            <div class="notify">
                                <div class="row">
                                    <div class="col">
                                        <div class="itm danger">
                                            <div class="icn">
                                                <img src="/img/icns/fill/16/alert.svg">
                                            </div>
                                            <div class="txt">Please enter up to five valid receiver email addresses, separated by commas.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-input">
                                        <label class="form-label" for="recipientEmail">Recipient's Email <span class="color-warning">*</span></label>
                                        <input class="form-control md" id="recipientEmail" name="emails" value="" type="email" placeholder="Enter email">
                                        <div class="form-note">Separate multiple addresses with a comma.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-input">
                                        <label class="form-label" for="yourEmail">Your Email <span class="color-warning">*</span></label>
                                        <input class="form-control md" id="yourEmail" name="fromEmail" value="jared@homescore.co" type="email" placeholder="Enter email">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-input">
                                        <label class="form-label" for="message">Include Message (optional):</label>
                                        <textarea class="form-control md" id="message" name="message" rows="" cols="">Check out this great find on PropertyChecker!</textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col cta">
                                    <button class="btn md arw send-shareable" type="button">Send Email</button>
                                </div>
                                <div class="col cta">
                                    <div class="get-link">
                                        <div class="lnk">
                                            <strong>
                                                <a href="javascript:;" style="color:#007BFF; display: inherit; font-size: inherit; filter:none" class="copy-share-link" data-link="">Get shareable link</a>
                                            </strong>
                                        </div>
                                        <div class="msg msg-link-copy" style="opacity:0">Link copied to clipboard</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a class="lbox-close" href="javascript:void(0)" onclick="lbHide('sendShareables')" title="Close">Close</a>
                </div>
            </div>
                    </div>

    </div>
</div>        
                                                                        <div class="r-section" id="section_top">                    <div class="r-sum">
    <div class="row">
                                    <div class="col">
                    <div class="cnt">
                        <span class="icn">
                            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/color/60/bedbath.svg" alt="" width="60" height="60">
                        </span>
                        <span class="txt">
                            <span class="val">3br / 1ba</span>
                            <span class="ttl">Beds/Baths</span>
                        </span>
                    </div>
                </div>
                            <div class="col">
                    <div class="cnt">
                        <span class="icn">
                            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/color/60/home_magnifier.svg" alt="" width="60" height="60">
                        </span>
                        <span class="txt">
                            <span class="val">1,878 sqft</span>
                            <span class="ttl">Living Area</span>
                        </span>
                    </div>
                </div>
                            <div class="col">
                    <div class="cnt">
                        <span class="icn">
                            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/color/60/map.svg" alt="" width="60" height="60">
                        </span>
                        <span class="txt">
                            <span class="val">0.69 acres</span>
                            <span class="ttl">Lot Size</span>
                        </span>
                    </div>
                </div>
                            <div class="col">
                    <div class="cnt">
                        <span class="icn">
                            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/color/60/building.svg" alt="" width="60" height="60">
                        </span>
                        <span class="txt">
                            <span class="val">1970</span>
                            <span class="ttl">Year Built</span>
                        </span>
                    </div>
                </div>
                            <div class="col">
                    <div class="cnt">
                        <span class="icn">
                            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/color/60/calendar.svg" alt="" width="60" height="60">
                        </span>
                        <span class="txt">
                            <span class="val">N/A</span>
                            <span class="ttl">Ownership Length</span>
                        </span>
                    </div>
                </div>
                            <div class="col">
                    <div class="cnt">
                        <span class="icn">
                            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/color/60/home_arrow_up.svg" alt="" width="60" height="60">
                        </span>
                        <span class="txt">
                            <span class="val">$622,500</span>
                            <span class="ttl">Market Value</span>
                        </span>
                    </div>
                </div>
                        </div>
</div>
        
                                            </div>                            <div class="r-section" id="section_summary">                    <div class="hdr">
    <h2 class="r-h2">
        <span class="icn">
            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/32/home.svg" alt="" width="32" height="32">
        </span>
        <span class="txt">Summary</span>

    </h2>
    </div>
<div class="bdy">
    <div class="sec">
                <div class="r-cnt">
            <div class="row">
                                    <div class="col" id="summaryMapStaticStreetViewContainer">
                        <div class="r-map" style="background-color: transparent">
                            <a href="https://www.google.com/maps/place/29+ROBINHOOD+LN%2C+BILLERICA%2C+MA+01821" target="_blank">
                                <img
                                    id="summaryMapStaticImg"
                                    class="display-static-map-lazy"
                                    alt="Map"
                                    style="border:0; margin: 0 auto;background-image:url('/tspecV2/PremiumPropertyReport/shared/img/spinner.svg');background-repeat:no-repeat;background-position:center center;background-size:20%"
                                    src="/tspecV2/PremiumPropertyReport/shared/img/noimage.svg"
                                                                    >
                                <!--                            <div id="summaryMapPanorama"></div>-->
                            </a>
                        </div>
                    </div>
                    <div class="col" id="summaryMapContainer">
                        <div class="r-map">
                            <a href="https://www.google.com/maps/place/29+ROBINHOOD+LN%2C+BILLERICA%2C+MA+01821" target="_blank">
<!--                                <div id="summaryMap"></div>-->
                                <img
                                    id="summaryMap"
                                    class="display-static-map-lazy"
                                    alt="Map"
                                    style="border:0; margin: 0 auto;background-image:url('/tspecV2/PremiumPropertyReport/shared/img/spinner.svg');background-repeat:no-repeat;background-position:center center;background-size:20%"
                                    src="/tspecV2/PremiumPropertyReport/shared/img/noimage.svg"
                                />
                            </a>
                        </div>
                    </div>
                            </div>
        </div>
        
        <div class="r-list">
                            <div class="row">
                                            <div class="col">
                            <div class="icn">
                                <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/pin.svg" alt="" width="16" height="16">
                            </div>
                            <div class="txt">
                                <div class="ttl">Property Address:</div>
                                <div class="val">29 ROBINHOOD LN</div>
                            </div>
                        </div>
                                            <div class="col">
                            <div class="icn">
                                <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/usa.svg" alt="" width="16" height="16">
                            </div>
                            <div class="txt">
                                <div class="ttl">City, State, Zipcode:</div>
                                <div class="val">BILLERICA, MA 01821</div>
                            </div>
                        </div>
                                            <div class="col">
                            <div class="icn">
                                <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/latitude.svg" alt="" width="16" height="16">
                            </div>
                            <div class="txt">
                                <div class="ttl">Latitude:</div>
                                <div class="val">42.557257</div>
                            </div>
                        </div>
                                            <div class="col">
                            <div class="icn">
                                <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/longitude.svg" alt="" width="16" height="16">
                            </div>
                            <div class="txt">
                                <div class="ttl">Longitude:</div>
                                <div class="val">-71.229458</div>
                            </div>
                        </div>
                                    </div>
                            <div class="row">
                                            <div class="col">
                            <div class="icn">
                                <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/flag.svg" alt="" width="16" height="16">
                            </div>
                            <div class="txt">
                                <div class="ttl">County:</div>
                                <div class="val">MIDDLESEX</div>
                            </div>
                        </div>
                                            <div class="col">
                            <div class="icn">
                                <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/document.svg" alt="" width="16" height="16">
                            </div>
                            <div class="txt">
                                <div class="ttl">APN:</div>
                                <div class="val">BILL M:0064 B:0088 L:0</div>
                            </div>
                        </div>
                                            <div class="col">
                            <div class="icn">
                                <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/document_gavel.svg" alt="" width="16" height="16">
                            </div>
                            <div class="txt">
                                <div class="ttl">Legal Description:</div>
                                <div class="val">BLK:88 DIST:31 CITY/MUNI/TWP:BILLERICA</div>
                            </div>
                        </div>
                                            <div class="col">
                            <div class="icn">
                                <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/government.svg" alt="" width="16" height="16">
                            </div>
                            <div class="txt">
                                <div class="ttl">Legal Subdivision:</div>
                                <div class="val">N/A</div>
                            </div>
                        </div>
                                    </div>
                            <div class="row">
                                            <div class="col">
                            <div class="icn">
                                <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/home.svg" alt="" width="16" height="16">
                            </div>
                            <div class="txt">
                                <div class="ttl">Property Type:</div>
                                <div class="val">SINGLE FAMILY RESIDENCE</div>
                            </div>
                        </div>
                                            <div class="col">
                            <div class="icn">
                                <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/home_user.svg" alt="" width="16" height="16">
                            </div>
                            <div class="txt">
                                <div class="ttl">Owner Status:</div>
                                <div class="val">OWNER OCCUPIED</div>
                            </div>
                        </div>
                                            <div class="col">
                            <div class="icn">
                                <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/home_user.svg" alt="" width="16" height="16">
                            </div>
                            <div class="txt">
                                <div class="ttl">Owner Type:</div>
                                <div class="val">Company</div>
                            </div>
                        </div>
                                            <div class="col">
                            <div class="icn">
                                <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/document.svg" alt="" width="16" height="16">
                            </div>
                            <div class="txt">
                                <div class="ttl">Trust Description:</div>
                                <div class="val">Name is a Trust</div>
                            </div>
                        </div>
                                    </div>
                            <div class="row">
                                            <div class="col">
                            <div class="icn">
                                <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/flag.svg" alt="" width="16" height="16">
                            </div>
                            <div class="txt">
                                <div class="ttl">Company Flag:</div>
                                <div class="val">YES</div>
                            </div>
                        </div>
                                            <div class="col">
                            <div class="icn">
                                <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/home_tag.svg" alt="" width="16" height="16">
                            </div>
                            <div class="txt">
                                <div class="ttl">Property Use:</div>
                                <div class="val">RESIDENTIAL</div>
                            </div>
                        </div>
                                            <div class="col">
                            <div class="icn">
                                <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/document.svg" alt="" width="16" height="16">
                            </div>
                            <div class="txt">
                                <div class="ttl">Stories:</div>
                                <div class="val">1</div>
                            </div>
                        </div>
                                            <div class="col">
                            <div class="icn">
                                <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/home.svg" alt="" width="16" height="16">
                            </div>
                            <div class="txt">
                                <div class="ttl">Structure Style:</div>
                                <div class="val">SPLIT ENTRY</div>
                            </div>
                        </div>
                                    </div>
                                </div>

    </div>
</div>

    <style>
        #summaryMap, #summaryMapPanorama, #summaryMapStatic {
            min-width: 400px;
            height: 200px
        }
    </style>
    <!--lazy load map src-->
    <script src="/tspecV2/PremiumPropertyReport/shared/js/lazyLoadStaticMap.js"></script>
    <script>
        let staticMapUrl = 'https://maps.googleapis.com/maps/api/staticmap?center=29+ROBINHOOD+LN%2C+BILLERICA%2C+MA+01821&sensor=false&size=585x200&zoom=15&markers=label%3A29+ROBINHOOD+LN%2C+BILLERICA%2C+MA+01821%7C29+ROBINHOOD+LN%2C+BILLERICA%2C+MA+01821&w=585&h=200&key=AIzaSyCtnZ-e78v92QBlLkUYVHXZPo913yldQu8';
        let mapData = JSON.parse('{"lat":42.557257,"lng":-71.229458}');
        document.addEventListener("DOMContentLoaded", function() {
            let staticStreetViewContainer = document.getElementById('summaryMapStaticStreetViewContainer');
            let summaryMapContainer = document.getElementById('summaryMapContainer');

            if (!mapData.hasOwnProperty('lat') || !mapData.hasOwnProperty('lng')) {
                console.error('No proper coordinates', mapData);
                staticStreetViewContainer.style.display = 'none';
                summaryMapContainer.style.display = 'none';
                return;
            }

            let summaryMapStaticImg = document.getElementById('summaryMapStaticImg');
            let mapId = 'summaryMap';

            // build streetview static meta url
            let apiKey = 'AIzaSyCtnZ-e78v92QBlLkUYVHXZPo913yldQu8';// StreetViewStaticMap should be allowed
            let staticStreetViewMetaUrl = new URL('https://maps.googleapis.com/maps/api/streetview/metadata?key=' + apiKey);
            let staticStreetViewMetaUrlParams = staticStreetViewMetaUrl.searchParams
            let staticStreetViewContainerOffsetWidth = staticStreetViewContainer.offsetWidth || 585;
            staticStreetViewMetaUrlParams.set('size', staticStreetViewContainerOffsetWidth + 'x200');
            staticStreetViewMetaUrlParams.set('location', mapData.lat + ',' + mapData.lng);

            // call meta
            // Street View Static API metadata requests are available at no charge. No quota is consumed when you request metadata.
            // Quota is only consumed when you load an image using the Street View Static API.
            let streetViewNotFound = false;
            fetch(staticStreetViewMetaUrl)
                .then(response => response.json())
                .then(results => {
                    if (results.status !== "OK") {
                        // hide static StreetView container
                        console.log('No Results', results);
                        streetViewNotFound = true; // not found
                        staticStreetViewContainer.style.display = 'none';

                        return;
                    }

                    // Build and Attach src to load Static StreetView
                    let staticStreetViewUrl = staticStreetViewMetaUrl.toString().replace(/\/metadata\?/, '\?');
                    loadMapImage(summaryMapStaticImg, staticStreetViewUrl);
                })
                .catch(er => {
                    console.log('staticStreetViewMetaUrl: ' + staticStreetViewMetaUrl);
                    console.error(er);
                    staticStreetViewContainer.style.display = 'none';
                })
                .finally(() => {
                    // Load static map to the specific container on full or half row depends on StreetView exists
                    let summaryMapContainerOffsetWidth = summaryMapContainer.offsetWidth || 585;
                    let scale = '1';
                    let height = '200';
                    let width = parseInt(summaryMapContainerOffsetWidth.toString());
                    if (width > 640) {
                        if ((width / 2) <= 640) {
                            width = Math.ceil(width / 2);
                            height = '100';
                            scale = '2';
                        } else {
                            width = '600';
                            height = '100';
                            scale = '2';
                        }
                    }
                    if (streetViewNotFound == true) {
                        let boundingRec = summaryMapContainer.getBoundingClientRect();
                        width = boundingRec.width;
                        height = boundingRec.height;
                    }
                    console.log (width + ' and ' + height)
                    let fullAddressCord = mapData.lat + ',' + mapData.lng;
                    let summaryMapImg = document.getElementById(mapId);
                    let staticMapUrl = new URL('https://maps.googleapis.com/maps/api/staticmap?key=' + apiKey);
                    let staticMapUrlParams =  staticMapUrl.searchParams;
                    staticMapUrlParams.set('size', width + 'x' + height)// max 640 but can be scaled x2
                    staticMapUrlParams.set('scale', scale)
                    staticMapUrlParams.set('zoom', '16')
                    if (streetViewNotFound == true) {
                        staticMapUrlParams.set('zoom', '15')
                        staticMapUrlParams.set('scale', '4')
                        summaryMapImg.style.backgroundSize = '10%';
                    }
                    staticMapUrlParams.set('center', mapData.lat + ',' + mapData.lng);
                    staticMapUrlParams.set('markers', 'color:red|size:small|' + fullAddressCord);
                    loadMapImage(summaryMapImg, staticMapUrl.toString());
                });

            function loadMapImage(el, src) {
                el.setAttribute('data-src', src);
            }
        });

    </script>
        
                                            </div>                            <div class="r-section" id="section_nav">                    <div class="bdy">
    <div class="sec">
        <div class="r-nav three-cols">
            <ul>
                                    <li>
                        <a class="smooth" href="#section_owners">
                        <span class="icn">
                            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/home_user.svg" alt="" width="16" height="16">
                        </span>
                            <span class="txt">Owners</span>
                        </a>
                    </li>
                                    <li>
                        <a class="smooth" href="#section_foreclosures">
                        <span class="icn">
                            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/home_gavel.svg" alt="" width="16" height="16">
                        </span>
                            <span class="txt">Foreclosures</span>
                        </a>
                    </li>
                                    <li>
                        <a class="smooth" href="#section_pre_foreclosures">
                        <span class="icn">
                            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/home_dollar.svg" alt="" width="16" height="16">
                        </span>
                            <span class="txt">Pre-Foreclosures</span>
                        </a>
                    </li>
                                    <li>
                        <a class="smooth" href="#section_taxes">
                        <span class="icn">
                            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/home_percent.svg" alt="" width="16" height="16">
                        </span>
                            <span class="txt">Taxes</span>
                        </a>
                    </li>
                                    <li>
                        <a class="smooth" href="#section_listings_history">
                        <span class="icn">
                            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/listings.svg" alt="" width="16" height="16">
                        </span>
                            <span class="txt">Listings History</span>
                        </a>
                    </li>
                                    <li>
                        <a class="smooth" href="#section_values">
                        <span class="icn">
                            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/home_dollar.svg" alt="" width="16" height="16">
                        </span>
                            <span class="txt">Values</span>
                        </a>
                    </li>
                                    <li>
                        <a class="smooth" href="#section_deeds">
                        <span class="icn">
                            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/document_stamp.svg" alt="" width="16" height="16">
                        </span>
                            <span class="txt">Deeds</span>
                        </a>
                    </li>
                                    <li>
                        <a class="smooth" href="#section_property_details">
                        <span class="icn">
                            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/home_magnifier.svg" alt="" width="16" height="16">
                        </span>
                            <span class="txt">Property Details</span>
                        </a>
                    </li>
                                    <li>
                        <a class="smooth" href="#section_loans">
                        <span class="icn">
                            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/hand_dollar.svg" alt="" width="16" height="16">
                        </span>
                            <span class="txt">Loans</span>
                        </a>
                    </li>
                                    <li>
                        <a class="smooth" href="#section_building_permits">
                        <span class="icn">
                            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/permit.svg" alt="" width="16" height="16">
                        </span>
                            <span class="txt">Building Permits</span>
                        </a>
                    </li>
                                    <li>
                        <a class="smooth" href="#section_liens_and_judgments">
                        <span class="icn">
                            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/gavel.svg" alt="" width="16" height="16">
                        </span>
                            <span class="txt">Liens & Judgments</span>
                        </a>
                    </li>
                                    <li>
                        <a class="smooth" href="#section_residents">
                        <span class="icn">
                            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/group.svg" alt="" width="16" height="16">
                        </span>
                            <span class="txt">Residents</span>
                        </a>
                    </li>
                                    <li>
                        <a class="smooth" href="#section_neighborhood">
                        <span class="icn">
                            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/pin_map.svg" alt="" width="16" height="16">
                        </span>
                            <span class="txt">Neighborhood</span>
                        </a>
                    </li>
                                    <li>
                        <a class="smooth" href="#section_sex_offenders">
                        <span class="icn">
                            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/avatar.svg" alt="" width="16" height="16">
                        </span>
                            <span class="txt">Sex Offenders</span>
                        </a>
                    </li>
                            </ul>
        </div>
    </div>
</div>

        
                                            </div>                            <div class="r-section" id="section_owners">                    <div class="hdr">
    <h2 class="r-h2">
        <span class="icn">
            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/32/home_user.svg" alt="" width="32" height="32">
        </span>
        <span class="txt">Owners</span>
    </h2>
</div>

<div class="bdy">
                        <div class="sec">
                <h3 class="r-h4 line">
                    <span class="txt">Owner's Name(s)</span>
                </h3>
                <div class="blk">
                                            <h4 class="r-h3">
                            <span class="icn">
                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/user.svg" alt="" width="16" height="16">
                            </span>
                            <span class="txt">
                                                                    POMPEI FT TRUST                                                            </span>
                        </h4>
                                    </div>
                                    <h3 class="r-h4 line">
                        <span class="txt">Owner's Mailing Address</span>
                    </h3>
                    <div class="blk">
                        <h4 class="r-h3">
                            <span class="icn">
                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/pin.svg" alt="" width="16" height="16">
                            </span>
                            <span class="txt">
                                <a href="#">29 Robinhood Ln, Billerica, MA 01821</a><!--TODO: link to what?-->
                            </span>
                        </h4>
                    </div>
                            </div>
        
                    <div class="sec">
                <h3 class="r-h3">
                    <span class="txt">Ownership Timeline</span>
                </h3>
                <div class="r-tline">
                                            <div class="row">
                            <div class="year">2020</div>
                            <div class="line"></div>
                            <div class="info">
                                <div class="name">
                                                                                                                        Pompei Ft Trust                                                                                                            </div>
                                                                    <div class="data">
                                        <div class="tl">Transaction Type:</div>
                                        <div class="vl">QUIT CLAIM DEED</div>
                                    </div>
                                                                                                    <div class="data">
                                        <div class="tl">Date of Transaction:</div>
                                        <div class="vl">02/10/2020</div>
                                    </div>
                                                                                            </div>
                        </div>
                                            <div class="row">
                            <div class="year">2017</div>
                            <div class="line"></div>
                            <div class="info">
                                <div class="name">
                                                                                                                        <a href="/members/person-search?firstName=Joseph&lastName=Pompei&city=BILLERICA&state=MA" class="">Joseph G Pompei</a><br>                                                                                    <a href="/members/person-search?firstName=Ivy&lastName=Pompei&city=BILLERICA&state=MA" class="">Ivy L Pompei</a>                                                                                                            </div>
                                                                    <div class="data">
                                        <div class="tl">Transaction Type:</div>
                                        <div class="vl">DEED OF TRUST</div>
                                    </div>
                                                                                                    <div class="data">
                                        <div class="tl">Date of Transaction:</div>
                                        <div class="vl">02/25/2017</div>
                                    </div>
                                                                                            </div>
                        </div>
                                            <div class="row">
                            <div class="year">2007</div>
                            <div class="line"></div>
                            <div class="info">
                                <div class="name">
                                                                                                                        <a href="/members/person-search?firstName=Ivy&lastName=Pompei&city=BILLERICA&state=MA" class="">Ivy L Pompei</a>                                                                                                            </div>
                                                                    <div class="data">
                                        <div class="tl">Transaction Type:</div>
                                        <div class="vl">DEED OF TRUST</div>
                                    </div>
                                                                                                    <div class="data">
                                        <div class="tl">Date of Transaction:</div>
                                        <div class="vl">08/20/2007</div>
                                    </div>
                                                                                            </div>
                        </div>
                                            <div class="row">
                            <div class="year">2002</div>
                            <div class="line"></div>
                            <div class="info">
                                <div class="name">
                                                                                                                        <a href="/members/person-search?firstName=Ivy&lastName=Pompei&city=BILLERICA&state=MA" class="">Ivy L Pompei</a>                                                                                                            </div>
                                                                    <div class="data">
                                        <div class="tl">Transaction Type:</div>
                                        <div class="vl">DEED OF TRUST</div>
                                    </div>
                                                                                                    <div class="data">
                                        <div class="tl">Date of Transaction:</div>
                                        <div class="vl">09/30/2002</div>
                                    </div>
                                                                                            </div>
                        </div>
                                            <div class="row">
                            <div class="year">2002</div>
                            <div class="line"></div>
                            <div class="info">
                                <div class="name">
                                                                                                                        <a href="/members/person-search?firstName=Ivy&lastName=Pompei&city=BILLERICA&state=MA" class="">Ivy L Pompei</a>                                                                                                            </div>
                                                                    <div class="data">
                                        <div class="tl">Transaction Type:</div>
                                        <div class="vl">DEED OF TRUST</div>
                                    </div>
                                                                                                    <div class="data">
                                        <div class="tl">Date of Transaction:</div>
                                        <div class="vl">09/30/2002</div>
                                    </div>
                                                                                            </div>
                        </div>
                                            <div class="row">
                            <div class="year">1998</div>
                            <div class="line"></div>
                            <div class="info">
                                <div class="name">
                                                                                                                        <a href="/members/person-search?firstName=Joseph&lastName=Pompei&city=BILLERICA&state=MA" class="">Joseph G Pompei</a>                                                                                                            </div>
                                                                    <div class="data">
                                        <div class="tl">Transaction Type:</div>
                                        <div class="vl">DEED OF TRUST</div>
                                    </div>
                                                                                                    <div class="data">
                                        <div class="tl">Date of Transaction:</div>
                                        <div class="vl">05/22/1998</div>
                                    </div>
                                                                                            </div>
                        </div>
                                            <div class="row">
                            <div class="year">1993</div>
                            <div class="line"></div>
                            <div class="info">
                                <div class="name">
                                                                                                                        <a href="/members/person-search?firstName=Joseph&lastName=Pompei&city=BILLERICA&state=MA" class="">Joseph G Pompei</a>                                                                                                            </div>
                                                                    <div class="data">
                                        <div class="tl">Transaction Type:</div>
                                        <div class="vl">DEED OF TRUST</div>
                                    </div>
                                                                                                    <div class="data">
                                        <div class="tl">Date of Transaction:</div>
                                        <div class="vl">04/20/1993</div>
                                    </div>
                                                                                            </div>
                        </div>
                                            <div class="row">
                            <div class="year">1992</div>
                            <div class="line"></div>
                            <div class="info">
                                <div class="name">
                                                                                                                        <a href="/members/person-search?firstName=Joseph&lastName=Pompei&city=BILLERICA&state=MA" class="">Joseph G Pompei</a>                                                                                                            </div>
                                                                    <div class="data">
                                        <div class="tl">Transaction Type:</div>
                                        <div class="vl">DEED OF TRUST</div>
                                    </div>
                                                                                                    <div class="data">
                                        <div class="tl">Date of Transaction:</div>
                                        <div class="vl">12/01/1992</div>
                                    </div>
                                                                                            </div>
                        </div>
                                            <div class="row">
                            <div class="year">1989</div>
                            <div class="line"></div>
                            <div class="info">
                                <div class="name">
                                                                                                                        <a href="/members/person-search?firstName=Joseph&lastName=Pompi&city=BILLERICA&state=MA" class="">Joseph Pompi</a>                                                                                                            </div>
                                                                    <div class="data">
                                        <div class="tl">Transaction Type:</div>
                                        <div class="vl">DEED OF TRUST</div>
                                    </div>
                                                                                                    <div class="data">
                                        <div class="tl">Date of Transaction:</div>
                                        <div class="vl">09/14/1989</div>
                                    </div>
                                                                                            </div>
                        </div>
                                    </div>
            </div>
        
            </div>

        
                                            </div>                            <div class="r-section" id="section_foreclosures">                    <div class="hdr">
    <h2 class="r-h2">
        <span class="icn">
            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/32/home_gavel.svg" alt="" width="32" height="32">
        </span>
        <span class="txt">Foreclosure</span>
    </h2>
</div>
<div class="bdy">
    <div class="sec">
        <div class="blk">
                            <p>This property is not classified as a foreclosure.</p>
                    </div>
<!--        <div class="r-results">-->
<!--            <div class="row">-->
<!--                --><!--                    <div class="col">-->
<!--                        <h4 class="r-h4">-->
<!--                            <span class="icn">-->
<!--                                <img src="--><!--" alt="" width="16" height="16">-->
<!--                            </span>-->
<!--                            <span class="txt">--><!--</span>-->
<!--                        </h4>-->
<!--                        <div class="r-table">-->
<!--                            --><!--                                <div class="row">-->
<!--                                    <div class="ttl">--><!--:</div>-->
<!--                                    <div class="val">--><!--</div>-->
<!--                                </div>-->
<!--                            --><!--                        </div>-->
<!--                        --><!--                            <div class="button">-->
<!--                                <div class="row">-->
<!--                                    <div class="col">-->
<!--                                        <a class="btn sm arw" href="--><!--">View Full Report</a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        --><!--                    </div>-->
<!--                --><!--            </div>-->
<!--        </div>-->
    </div>
</div>
        
                                            </div>                            <div class="r-section" id="section_pre_foreclosures">                    <div class="hdr">
    <h2 class="r-h2">
        <span class="icn">
            <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/32/home_dollar.svg" alt="" width="32" height="32">
        </span>
        <span class="txt">Pre-Foreclosures</span>
    </h2>
</div>
<div class="bdy">
            <div class="notify">
    <div class="row">
        <div class="col">
            <div class="itm">
                <div class="icn filter-special">
                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/info.svg">
                </div>
                <div class="txt">We could not locate any pre-foreclosure records. It's important to keep in mind that the lack of information or results in our reports may not always be entirely accurate, comprehensive, or current. Therefore, we kindly advise against solely relying on our findings and recommend conducting your own thorough research.</div>
            </div>
        </div>
    </div>
</div>
    </div>

        
                                            </div>                            <div class="r-section" id="section_taxes">                    <div class="hdr">
    <h2 class="r-h2">
        <span class="icn">
            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/32/home_percent.svg" alt="" width="32" height="32">
        </span>
        <span class="txt">Taxes</span>
    </h2>
</div>
<div class="bdy">
            <div class="sec">
            <div class="r-list">
                                    <div class="row">
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/home_percent.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Tax Bill Amount:</div>
                                    <div class="val">$6,289</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/calendar_dollar.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Tax Year Assessed:</div>
                                    <div class="val">2024</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/home_dollar.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Total Assessed Value:</div>
                                    <div class="val">$557,000</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/map_pin.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Land Assessed Value:</div>
                                    <div class="val">$319,800</div>
                                </div>
                            </div>
                                            </div>
                                    <div class="row">
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/home_tag.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Additions Assessed Value:</div>
                                    <div class="val">$237,200</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/calculator.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Tax Assessed Change %:</div>
                                    <div class="val">42%</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/dollar_clock.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Previous Assessed Value:</div>
                                    <div class="val">$518,600</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/document_dollar_update.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Last Assessor Tax Roll Update:</div>
                                    <div class="val">01/01/2024</div>
                                </div>
                            </div>
                                            </div>
                                    <div class="row">
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/user_update.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Assessor Last Updated:</div>
                                    <div class="val">2024</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/home.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Zoning Category:</div>
                                    <div class="val">Company</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/pin_number.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Zoned Code Local:</div>
                                    <div class="val">3</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/pin_number.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Zoning Type:</div>
                                    <div class="val">RESIDENTIAL</div>
                                </div>
                            </div>
                                            </div>
                                    <div class="row">
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/certificate.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Property Use Standardized:</div>
                                    <div class="val">385</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/pin.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Property Use Municode:</div>
                                    <div class="val">101</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/calendar.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Year Built:</div>
                                    <div class="val">1970</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/document_dollar_no.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Tax Exemption:</div>
                                    <div class="val">N/A</div>
                                </div>
                            </div>
                                            </div>
                                    <div class="row">
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/dollar_warning.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Tax Delinquent:</div>
                                    <div class="val">N/A</div>
                                </div>
                            </div>
                                            </div>
                            </div>
        </div>
    </div>        
                                            </div>                            <div class="r-section" id="section_tax_history">                    <div class="hdr">
    <h2 class="r-h2">
        <span class="icn">
            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/32/calendar_percent.svg" alt="" width="32" height="32">
        </span>
        <span class="txt">Tax History</span>
    </h2>
</div>
<div class="bdy">
            <div class="sec">
            <div class="r-tbl">
                <table>
                    <thead>
                        <tr>
                            <th>Year</th>
                            <th>Property Taxes</th>
                            <th>Change</th>
                            <th>Tax Assessment</th>
                            <th>Change</th>
                        </tr>
                    </thead>
                    <tbody>
                                                    <tr>
                                <td>2023</td>
                                <td>$6,156</td>
                                <td class="color-success">9.03%</td>
                                <td>$518,600</td>
                                <td class="color-success">16.10%</td>
                            </tr>
                                                    <tr>
                                <td>2022</td>
                                <td>$5,646</td>
                                <td class="color-success">5.93%</td>
                                <td>$446,700</td>
                                <td class="color-success">8.95%</td>
                            </tr>
                                                    <tr>
                                <td>2021</td>
                                <td>$5,330</td>
                                <td class="color-success">2.22%</td>
                                <td>$410,000</td>
                                <td class="color-success">2.14%</td>
                            </tr>
                                                    <tr>
                                <td>2020</td>
                                <td>$5,214</td>
                                <td class="color-warning">-0.61%</td>
                                <td>$401,400</td>
                                <td class="color-success">3.13%</td>
                            </tr>
                                                    <tr>
                                <td>2019</td>
                                <td>$5,246</td>
                                <td class="color-success">4.34%</td>
                                <td>$389,200</td>
                                <td class="color-success">9.85%</td>
                            </tr>
                                                    <tr>
                                <td>2018</td>
                                <td>$5,028</td>
                                <td class="color-success">5.23%</td>
                                <td>$354,300</td>
                                <td class="color-success">4.48%</td>
                            </tr>
                                                    <tr>
                                <td>2017</td>
                                <td>$4,778</td>
                                <td class="color-success">0.93%</td>
                                <td>$339,100</td>
                                <td class="color-success">1.28%</td>
                            </tr>
                                                    <tr>
                                <td>2016</td>
                                <td>$4,734</td>
                                <td class="color-success">2.40%</td>
                                <td>$334,800</td>
                                <td class="color-success">1.67%</td>
                            </tr>
                                                    <tr>
                                <td>2015</td>
                                <td>$4,623</td>
                                <td class="color-warning">-1.28%</td>
                                <td>$329,300</td>
                                <td class="color-success">0.49%</td>
                            </tr>
                                                    <tr>
                                <td>2014</td>
                                <td>$4,683</td>
                                <td class="color-success">1.19%</td>
                                <td>$327,700</td>
                                <td class="color-success">1.33%</td>
                            </tr>
                                                    <tr>
                                <td>2013</td>
                                <td>$4,628</td>
                                <td class="color-success">2.80%</td>
                                <td>$323,400</td>
                                <td class="color-warning">0.00%</td>
                            </tr>
                                                    <tr>
                                <td>2012</td>
                                <td>$4,502</td>
                                <td class="color-none">-</td>
                                <td>$323,400</td>
                                <td class="color-none">-</td>
                            </tr>
                                            </tbody>
                </table>
            </div>
        </div>
    </div>        
                                            </div>                            <div class="r-section" id="section_listings_history">                    
<div class="hdr">
    <h2 class="r-h2">
        <span class="icn">
            <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/32/listings.svg" alt="" width="32" height="32">
        </span>
        <span class="txt">Listings History</span>
    </h2>
</div>
<div class="bdy">
            <div class="sec">
            <h3 class="r-h3">
                <span class="txt">Listing Description</span>
            </h3>
            <div class="blk">
                <p>This 1878 square foot single family home has 3 bedrooms and 1.0 bathrooms. This home is located at 29 Robinhood Ln, Billerica, MA 01821.</p>
            </div>
        </div>
                <div class="sec">
            <h3 class="r-h3">
                <span class="txt">Interior</span>
            </h3>
            <div class="r-table two-cols">
                                    <div class="row">
                        <div class="ttl">Bedrooms:</div>
                        <div class="val">3</div>
                    </div>
                                    <div class="row">
                        <div class="ttl">Bathrooms:</div>
                        <div class="val">1</div>
                    </div>
                                    <div class="row">
                        <div class="ttl">Full Bathrooms:</div>
                        <div class="val">Not on File</div>
                    </div>
                                    <div class="row">
                        <div class="ttl">Half Bathrooms:</div>
                        <div class="val">Not on File</div>
                    </div>
                                    <div class="row">
                        <div class="ttl">Heating:</div>
                        <div class="val">Other, Gas</div>
                    </div>
                                    <div class="row">
                        <div class="ttl">Kitchen Features:</div>
                        <div class="val">Not on File</div>
                    </div>
                                    <div class="row">
                        <div class="ttl">Other Interior Features:</div>
                        <div class="val">Total interior livable area: 1,878 sqft</div>
                    </div>
                            </div>
        </div>
    </div>

        
                                            </div>                            <div class="r-section" id="section_values">                    <div class="hdr">
    <h2 class="r-h2">
        <span class="icn">
            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/32/home_dollar.svg" alt="" width="32" height="32">
        </span>
        <span class="txt">Values</span>
    </h2>
</div>
<div class="bdy">
            <div class="sec">
            <div class="r-list">
                                    <div class="row">
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/home_dollar.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Market Value Estimate:</div>
                                    <div class="val">$622,500</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/home_ruller.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Property Square Footage:</div>
                                    <div class="val">1,878 Sq Ft</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/map_dollar.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Market Value Per Sq Ft:</div>
                                    <div class="val">$331 / Sq Ft</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/home_tag.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Rent Estimate:</div>
                                    <div class="val">$2,814</div>
                                </div>
                            </div>
                                            </div>
                                    <div class="row">
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/calendar_dollar.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Last Sale Date:</div>
                                    <div class="val">N/A</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/dollar.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Last Sale Amount:</div>
                                    <div class="val">N/A</div>
                                </div>
                            </div>
                                            </div>
                            </div>
        </div>
    </div>        
                                            </div>                            <div class="r-section" id="section_deeds">                    <div class="hdr">
    <h2 class="r-h2">
        <span class="icn">
            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/32/document_stamp.svg" alt="" width="32" height="32">
        </span>
        <span class="txt">Deeds</span>
    </h2>
</div>
<div class="bdy">
            <div class="sec">
            <div class="r-list">
                                    <div class="row">
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/user.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Grantee’s Name(s):</div>
                                    <div class="val">POMPEI FT TRUST</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/pin.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Deed Owner Address:</div>
                                    <div class="val">29 ROBINHOOD LN, BILLERICA, MA 01821</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/home_user.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Ownership Category:</div>
                                    <div class="val">Company</div>
                                </div>
                            </div>
                                            </div>
                                    <div class="row">
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/user.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Owner Occupied:</div>
                                    <div class="val">1</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/id.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Grantor’s Name(s):</div>
                                    <div class="val">N/A</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/dollar.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Transaction Amount:</div>
                                    <div class="val">N/A</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/hand_dollar.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Transaction Mortgage Amount:</div>
                                    <div class="val">N/A</div>
                                </div>
                            </div>
                                            </div>
                                    <div class="row">
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/cards.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Transaction Type:</div>
                                    <div class="val">N/A</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/certificate.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Document:</div>
                                    <div class="val">33923-202</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/city.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Title Company:</div>
                                    <div class="val">N/A</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/calendar.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Transaction Date:</div>
                                    <div class="val">03/17/2020</div>
                                </div>
                            </div>
                                            </div>
                                    <div class="row">
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/document.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Document Type:</div>
                                    <div class="val">N/A</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/home.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Property Type:</div>
                                    <div class="val">Residential</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/flag.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">County:</div>
                                    <div class="val">N/A</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/document_number.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Transfer Doc #:</div>
                                    <div class="val">N/A</div>
                                </div>
                            </div>
                                            </div>
                                    <div class="row">
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/bill.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Transfer Transaction #:</div>
                                    <div class="val">33923-202</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/book_number.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Sale Document Book #:</div>
                                    <div class="val">N/A</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/document_clock.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Last Document #:</div>
                                    <div class="val">N/A</div>
                                </div>
                            </div>
                                            </div>
                            </div>
        </div>
    </div>
        
                                            </div>                            <div class="r-section" id="section_property_details">                    <div class="hdr">
    <h2 class="r-h2">
        <span class="icn">
            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/32/home_magnifier.svg" alt="" width="32" height="32">
        </span>
        <span class="txt">Property Details</span>
    </h2>
</div>
<div class="bdy">
            <div class="sec">
            <div class="r-list">
                                    <div class="row">
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/bed.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Bedrooms:</div>
                                    <div class="val">3</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/bath.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Bathrooms:</div>
                                    <div class="val">1</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/dividers.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Living Area:</div>
                                    <div class="val">1,878 Sq Ft</div>
                                </div>
                            </div>
                                            </div>
                                    <div class="row">
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/dividers.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Area Building Code:</div>
                                    <div class="val">10</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/ruller.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Lot Size:</div>
                                    <div class="val">0.69 Acres</div>
                                </div>
                            </div>
                                                    <div class="col">
                                <div class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/calendar.svg" alt="" width="16" height="16">
                                </div>
                                <div class="txt">
                                    <div class="ttl">Year Built:</div>
                                    <div class="val">1970</div>
                                </div>
                            </div>
                                            </div>
                            </div>
        </div>

                    <div class="sec acc">
                <h3 class="r-h3 acc-head">
                    <span class="icn">
                        <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/24/room.svg" alt="" width="24" height="24">
                    </span>
                    <span class="txt">Rooms</span>
                </h3>
                <div class="acc-body">
                    <div class="r-table two-cols">
                                                    <div class="row">
                                <div class="ttl">Bath Count:</div>
                                <div class="val">1</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Bath Partial Count:</div>
                                <div class="val">0</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Bedrooms Count:</div>
                                <div class="val">3</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Rooms Count:</div>
                                <div class="val">8</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Stories Count:</div>
                                <div class="val">1</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Units Count:</div>
                                <div class="val">0</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Bonus Room:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Breakfast Nook:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Cellar Room:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Wine Cellar Room:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Exercise Room:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Family Room:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Game Room:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Great Room:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Hobby Room:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Laundry Room:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Media Room:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Mud Room:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Office Room:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Safe Room:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Sitting Room:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Storm Room:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Study Room:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Sunroom:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Utility Room:</div>
                                <div class="val">Not on File</div>
                            </div>
                                            </div>
                </div>
            </div>
                    <div class="sec acc">
                <h3 class="r-h3 acc-head">
                    <span class="icn">
                        <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/24/home_dimentions.svg" alt="" width="24" height="24">
                    </span>
                    <span class="txt">Area</span>
                </h3>
                <div class="acc-body">
                    <div class="r-table two-cols">
                                                    <div class="row">
                                <div class="ttl">Building:</div>
                                <div class="val">1878</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Building Definition Code:</div>
                                <div class="val">Living Area</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Gross:</div>
                                <div class="val">3626</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">1st Floor:</div>
                                <div class="val">0</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">2nd Floor:</div>
                                <div class="val">0</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Upper Floors:</div>
                                <div class="val">0</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Lot Acres:</div>
                                <div class="val">0.69 Acres</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Lot SF:</div>
                                <div class="val">30113.00</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Lot Depth:</div>
                                <div class="val">0.0</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Lot Width:</div>
                                <div class="val">0.0</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Rooms Attic:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Rooms Basement:</div>
                                <div class="val">1456</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Rooms Basement Finished:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Patio:</div>
                                <div class="val">0</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Deck:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Balcony:</div>
                                <div class="val">Not on File</div>
                            </div>
                                            </div>
                </div>
            </div>
                    <div class="sec acc">
                <h3 class="r-h3 acc-head">
                    <span class="icn">
                        <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/24/parking.svg" alt="" width="24" height="24">
                    </span>
                    <span class="txt">Parking</span>
                </h3>
                <div class="acc-body">
                    <div class="r-table two-cols">
                                                    <div class="row">
                                <div class="ttl">Garage:</div>
                                <div class="val">Garage, Basement</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Garage Area:</div>
                                <div class="val">0</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Carport:</div>
                                <div class="val">0</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">RV Parking Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Space Count:</div>
                                <div class="val">1</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Driveway Area:</div>
                                <div class="val">0</div>
                            </div>
                                            </div>
                </div>
            </div>
                    <div class="sec acc">
                <h3 class="r-h3 acc-head">
                    <span class="icn">
                        <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/24/home_hammer.svg" alt="" width="24" height="24">
                    </span>
                    <span class="txt">Construction Details</span>
                </h3>
                <div class="acc-body">
                    <div class="r-table two-cols">
                                                    <div class="row">
                                <div class="ttl">Roof Material:</div>
                                <div class="val">Asphalt</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Roof Construction:</div>
                                <div class="val">GABLE</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Structure Style:</div>
                                <div class="val">SPLIT ENTRY</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Exterior Code:</div>
                                <div class="val">WOOD SIDING</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Foundation:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Construction:</div>
                                <div class="val">FRAME</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Interior Structure:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Plumbing Fixtures Count:</div>
                                <div class="val">0</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Safety Fire Sprinklers Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                            </div>
                </div>
            </div>
                    <div class="sec acc">
                <h3 class="r-h3 acc-head">
                    <span class="icn">
                        <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/24/pool.svg" alt="" width="24" height="24">
                    </span>
                    <span class="txt">Property Features</span>
                </h3>
                <div class="acc-body">
                    <div class="r-table two-cols">
                                                    <div class="row">
                                <div class="ttl">Fireplace:</div>
                                <div class="val">YES</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Fireplace Count:</div>
                                <div class="val">2</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Pool:</div>
                                <div class="val">TYPE NOT SPECIFIED</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Pool Area:</div>
                                <div class="val">0</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Security Alarm Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Accessibility Elevator Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Accessibility Handicap Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Escalator Flag:</div>
                                <div class="val">0</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Central Vacuum Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Content Intercom Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Content Sound System Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Wet Bar Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Content Storm Shutter Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Content Overhead Door Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Porch Code:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Porch Area:</div>
                                <div class="val">0</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Deck Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Feature Balcony Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Breezeway Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Topography Code:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Fence Area:</div>
                                <div class="val">0</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Courtyard Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Arbor Pergola Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Golf Course Green Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Tennis Court Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Sports Court Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Arena Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Water Feature Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Pond Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Boat Lift Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Buildings Count:</div>
                                <div class="val">1</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Bath House Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Boat Access Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Boat House Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Cabin Area:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Cabin Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Canopy Area:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Canopy Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Gazebo Area:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Gazebo Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Grainery Area:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Grainery Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Green House Area:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Green House Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Guest House Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Kennel Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Milk House Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Outdoor Kitchen Fireplace Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Pool House Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Poultry House Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Quonset Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Shed Code:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Silo Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Stable Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Storage Building Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Pole Structure Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Publication Date:</div>
                                <div class="val">03/20/2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Parcel Shell Record:</div>
                                <div class="val">Not on File</div>
                            </div>
                                            </div>
                </div>
            </div>
                    <div class="sec acc">
                <h3 class="r-h3 acc-head">
                    <span class="icn">
                        <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/24/home_bulb.svg" alt="" width="24" height="24">
                    </span>
                    <span class="txt">Utilities / Green Energy Details</span>
                </h3>
                <div class="acc-body">
                    <div class="r-table two-cols">
                                                    <div class="row">
                                <div class="ttl">HVAC Cooling Detail:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">HVAC Heating Detail:</div>
                                <div class="val">HOT WATER</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">HVAC Heating Fuel:</div>
                                <div class="val">GAS</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Utilities Sewage Usage:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Utilities Water Source:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Utilities Mobile Home Hookup Flag:</div>
                                <div class="val">Not on File</div>
                            </div>
                                            </div>
                </div>
            </div>
                    <div class="sec acc">
                <h3 class="r-h3 acc-head">
                    <span class="icn">
                        <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/24/map_pin.svg" alt="" width="24" height="24">
                    </span>
                    <span class="txt">Parcel</span>
                </h3>
                <div class="acc-body">
                    <div class="r-table two-cols">
                                                    <div class="row">
                                <div class="ttl">Raw:</div>
                                <div class="val">BILL M:0064 B:0088 L:0</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Formatted:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Year Added:</div>
                                <div class="val">2007</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Alternate:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Map Book:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Map Page:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Year Change:</div>
                                <div class="val">0</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Previous:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Account Number:</div>
                                <div class="val">Not on File</div>
                            </div>
                                            </div>
                </div>
            </div>
                    <div class="sec acc">
                <h3 class="r-h3 acc-head">
                    <span class="icn">
                        <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/24/gavel.svg" alt="" width="24" height="24">
                    </span>
                    <span class="txt">Legal</span>
                </h3>
                <div class="acc-body">
                    <div class="r-table two-cols">
                                                    <div class="row">
                                <div class="ttl">Description:</div>
                                <div class="val">BLK:88 DIST:31 CITY/MUNI/TWP:BILLERICA</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Range:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Township:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Section:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Quarter:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Subdivision:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Phase:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Tract Number:</div>
                                <div class="val">0</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Block 1:</div>
                                <div class="val">88</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Block 2:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Lot Number 1:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Lot Number 2:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Lot Number 3:</div>
                                <div class="val">Not on File</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Unit:</div>
                                <div class="val">Not on File</div>
                            </div>
                                            </div>
                </div>
            </div>
            </div>        
                                            </div>                            <div class="r-section" id="section_loans">                    <div class="hdr">
    <h2 class="r-h2">
        <span class="icn">
            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/32/hand_dollar.svg" alt="" width="32" height="32">
        </span>
        <span class="txt">Loans</span>
    </h2>
</div>
<div class="bdy">
                        <div class="sec">
                <h3 class="r-h3">
                    <span class="txt">Previous Loans</span>
                </h3>
                <div class="r-results">
                    <div class="row">
                                                    <div class="col">
                                <h4 class="r-h4">
                                    <span class="icn">
                                        <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/hand_dollar.svg" alt="" width="16" height="16">
                                    </span>
                                    <span class="txt">Loan #1</span>
                                </h4>
                                <div class="r-table">
                                                                            <div class="row">
                                            <div class="ttl">Transaction Mortgage Amount:</div>
                                            <div class="val">$250,000</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Mortgage Date:</div>
                                            <div class="val">03/10/2017</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Lender Type:</div>
                                            <div class="val">B</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Loan Type:</div>
                                            <div class="val">6</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Lender:</div>
                                            <div class="val">BANK OF AMERICA</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Lender Code:</div>
                                            <div class="val">563715</div>
                                        </div>
                                                                    </div>
                            </div>
                                                    <div class="col">
                                <h4 class="r-h4">
                                    <span class="icn">
                                        <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/hand_dollar.svg" alt="" width="16" height="16">
                                    </span>
                                    <span class="txt">Loan #2</span>
                                </h4>
                                <div class="r-table">
                                                                            <div class="row">
                                            <div class="ttl">Transaction Mortgage Amount:</div>
                                            <div class="val">$200,000</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Mortgage Date:</div>
                                            <div class="val">08/20/2007</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Lender Type:</div>
                                            <div class="val">B</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Loan Type:</div>
                                            <div class="val">N/A</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Lender:</div>
                                            <div class="val">BANK OF AMERICA</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Lender Code:</div>
                                            <div class="val">563715</div>
                                        </div>
                                                                    </div>
                            </div>
                                                    <div class="col">
                                <h4 class="r-h4">
                                    <span class="icn">
                                        <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/hand_dollar.svg" alt="" width="16" height="16">
                                    </span>
                                    <span class="txt">Loan #3</span>
                                </h4>
                                <div class="r-table">
                                                                            <div class="row">
                                            <div class="ttl">Transaction Mortgage Amount:</div>
                                            <div class="val">$25,000</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Mortgage Date:</div>
                                            <div class="val">09/30/2002</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Lender Type:</div>
                                            <div class="val">M</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Loan Type:</div>
                                            <div class="val">N/A</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Lender:</div>
                                            <div class="val">COUNTRYWIDE HOME LOANS INC</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Lender Code:</div>
                                            <div class="val">507527</div>
                                        </div>
                                                                    </div>
                            </div>
                                                    <div class="col">
                                <h4 class="r-h4">
                                    <span class="icn">
                                        <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/hand_dollar.svg" alt="" width="16" height="16">
                                    </span>
                                    <span class="txt">Loan #4</span>
                                </h4>
                                <div class="r-table">
                                                                            <div class="row">
                                            <div class="ttl">Transaction Mortgage Amount:</div>
                                            <div class="val">$90,000</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Mortgage Date:</div>
                                            <div class="val">09/30/2002</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Lender Type:</div>
                                            <div class="val">M</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Loan Type:</div>
                                            <div class="val">N/A</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Lender:</div>
                                            <div class="val">COUNTRYWIDE HOME LOANS INC</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Lender Code:</div>
                                            <div class="val">507527</div>
                                        </div>
                                                                    </div>
                            </div>
                                                    <div class="col">
                                <h4 class="r-h4">
                                    <span class="icn">
                                        <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/hand_dollar.svg" alt="" width="16" height="16">
                                    </span>
                                    <span class="txt">Loan #5</span>
                                </h4>
                                <div class="r-table">
                                                                            <div class="row">
                                            <div class="ttl">Transaction Mortgage Amount:</div>
                                            <div class="val">$45,000</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Mortgage Date:</div>
                                            <div class="val">05/22/1998</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Lender Type:</div>
                                            <div class="val">B</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Loan Type:</div>
                                            <div class="val">N/A</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Lender:</div>
                                            <div class="val">BANKBOSTON DEVELOPMENT CO LLC</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Lender Code:</div>
                                            <div class="val">38635</div>
                                        </div>
                                                                    </div>
                            </div>
                                                    <div class="col">
                                <h4 class="r-h4">
                                    <span class="icn">
                                        <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/hand_dollar.svg" alt="" width="16" height="16">
                                    </span>
                                    <span class="txt">Loan #6</span>
                                </h4>
                                <div class="r-table">
                                                                            <div class="row">
                                            <div class="ttl">Transaction Mortgage Amount:</div>
                                            <div class="val">$144,000</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Mortgage Date:</div>
                                            <div class="val">04/20/1993</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Lender Type:</div>
                                            <div class="val">F</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Loan Type:</div>
                                            <div class="val">N/A</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Lender:</div>
                                            <div class="val">COUNTRYWIDE FUNDING CORP</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Lender Code:</div>
                                            <div class="val">1334</div>
                                        </div>
                                                                    </div>
                            </div>
                                                    <div class="col">
                                <h4 class="r-h4">
                                    <span class="icn">
                                        <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/hand_dollar.svg" alt="" width="16" height="16">
                                    </span>
                                    <span class="txt">Loan #7</span>
                                </h4>
                                <div class="r-table">
                                                                            <div class="row">
                                            <div class="ttl">Transaction Mortgage Amount:</div>
                                            <div class="val">$144,500</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Mortgage Date:</div>
                                            <div class="val">12/01/1992</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Lender Type:</div>
                                            <div class="val">F</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Loan Type:</div>
                                            <div class="val">N/A</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Lender:</div>
                                            <div class="val">COUNTRYWIDE FUNDING CORP</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Lender Code:</div>
                                            <div class="val">1334</div>
                                        </div>
                                                                    </div>
                            </div>
                                                    <div class="col">
                                <h4 class="r-h4">
                                    <span class="icn">
                                        <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/hand_dollar.svg" alt="" width="16" height="16">
                                    </span>
                                    <span class="txt">Loan #8</span>
                                </h4>
                                <div class="r-table">
                                                                            <div class="row">
                                            <div class="ttl">Transaction Mortgage Amount:</div>
                                            <div class="val">$150,000</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Mortgage Date:</div>
                                            <div class="val">09/14/1989</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Lender Type:</div>
                                            <div class="val">M</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Loan Type:</div>
                                            <div class="val">N/A</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Lender:</div>
                                            <div class="val">POWDER HOUSE MORTGAGE CO</div>
                                        </div>
                                                                            <div class="row">
                                            <div class="ttl">Lender Code:</div>
                                            <div class="val">40201</div>
                                        </div>
                                                                    </div>
                            </div>
                                            </div>
                </div>
            </div>
            <!--Old cached report data-->
    </div>        
                                            </div>                            <div class="r-section" id="section_building_permits">                    <div class="hdr">
    <h2 class="r-h2">
        <span class="icn">
            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/32/permit.svg" alt="" width="32" height="32">
        </span>
        <span class="txt">Building Permits</span>
    </h2>
</div>
<div class="bdy">
            <div class="sec">
            <div class="r-results">
                <div class="row">
                                            <div class="col">
                            <h4 class="r-h4">
                                <span class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/permit.svg" alt="" width="16" height="16">
                                </span>
                                <span class="txt">Permit #1</span>
                            </h4>
                            <div class="r-table">
                                                                    <div class="row">
                                        <div class="ttl">Effective Date:</div>
                                        <div class="val">07/22/2024</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Job Value:</div>
                                        <div class="val">$9,960</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Status:</div>
                                        <div class="val">issued</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Type:</div>
                                        <div class="val">Building</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Sub Type:</div>
                                        <div class="val">Insulation</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Business Name:</div>
                                        <div class="val">Polar Bear Insulation CO INC</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Home Owner:</div>
                                        <div class="val">N/A</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Fees:</div>
                                        <div class="val">$120</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Permit Number:</div>
                                        <div class="val">BP-24-888</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Description:</div>
                                        <div class="val">Air sealing attic insulation to code ventilation crawl space insulation removal and ro do with r-30+2"thermal barrier</div>
                                    </div>
                                                            </div>
                        </div>
                                            <div class="col">
                            <h4 class="r-h4">
                                <span class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/permit.svg" alt="" width="16" height="16">
                                </span>
                                <span class="txt">Permit #2</span>
                            </h4>
                            <div class="r-table">
                                                                    <div class="row">
                                        <div class="ttl">Effective Date:</div>
                                        <div class="val">07/09/2024</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Job Value:</div>
                                        <div class="val">$9,960</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Status:</div>
                                        <div class="val">issued</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Type:</div>
                                        <div class="val">Building</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Sub Type:</div>
                                        <div class="val">Insulation</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Business Name:</div>
                                        <div class="val">Polar Bear Insulation CO INC</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Home Owner:</div>
                                        <div class="val">N/A</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Fees:</div>
                                        <div class="val">$120</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Permit Number:</div>
                                        <div class="val">BP-24-813</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Description:</div>
                                        <div class="val">Air sealing attic insulation blown cellulose to code ventilation basement ceiling r-30 fiberglass 2"thermal barrier board</div>
                                    </div>
                                                            </div>
                        </div>
                                            <div class="col">
                            <h4 class="r-h4">
                                <span class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/permit.svg" alt="" width="16" height="16">
                                </span>
                                <span class="txt">Permit #3</span>
                            </h4>
                            <div class="r-table">
                                                                    <div class="row">
                                        <div class="ttl">Effective Date:</div>
                                        <div class="val">04/11/2024</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Job Value:</div>
                                        <div class="val">$3,000</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Status:</div>
                                        <div class="val">final</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Type:</div>
                                        <div class="val">plumbing</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Sub Type:</div>
                                        <div class="val">N/A</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Business Name:</div>
                                        <div class="val">N/A</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Home Owner:</div>
                                        <div class="val">N/A</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Fees:</div>
                                        <div class="val">$85</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Permit Number:</div>
                                        <div class="val">PP-24-211</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Description:</div>
                                        <div class="val">N/A</div>
                                    </div>
                                                            </div>
                        </div>
                                            <div class="col">
                            <h4 class="r-h4">
                                <span class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/permit.svg" alt="" width="16" height="16">
                                </span>
                                <span class="txt">Permit #4</span>
                            </h4>
                            <div class="r-table">
                                                                    <div class="row">
                                        <div class="ttl">Effective Date:</div>
                                        <div class="val">04/11/2024</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Job Value:</div>
                                        <div class="val">$4,000</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Status:</div>
                                        <div class="val">final</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Type:</div>
                                        <div class="val">plumbing</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Sub Type:</div>
                                        <div class="val">N/A</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Business Name:</div>
                                        <div class="val">N/A</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Home Owner:</div>
                                        <div class="val">N/A</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Fees:</div>
                                        <div class="val">$75</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Permit Number:</div>
                                        <div class="val">PP-24-212</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Description:</div>
                                        <div class="val">N/A</div>
                                    </div>
                                                            </div>
                        </div>
                                            <div class="col">
                            <h4 class="r-h4">
                                <span class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/permit.svg" alt="" width="16" height="16">
                                </span>
                                <span class="txt">Permit #5</span>
                            </h4>
                            <div class="r-table">
                                                                    <div class="row">
                                        <div class="ttl">Effective Date:</div>
                                        <div class="val">03/06/2024</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Job Value:</div>
                                        <div class="val">$6,958</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Status:</div>
                                        <div class="val">issued</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Type:</div>
                                        <div class="val">Building</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Sub Type:</div>
                                        <div class="val">Renovations</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Business Name:</div>
                                        <div class="val">Servpro OF Lawrence</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Home Owner:</div>
                                        <div class="val">N/A</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Fees:</div>
                                        <div class="val">$83</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Permit Number:</div>
                                        <div class="val">BP-24-253</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Description:</div>
                                        <div class="val">Removal of impacted ceilings and walls in storage and laundry room in basement after water damage</div>
                                    </div>
                                                            </div>
                        </div>
                                            <div class="col">
                            <h4 class="r-h4">
                                <span class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/permit.svg" alt="" width="16" height="16">
                                </span>
                                <span class="txt">Permit #6</span>
                            </h4>
                            <div class="r-table">
                                                                    <div class="row">
                                        <div class="ttl">Effective Date:</div>
                                        <div class="val">05/25/2023</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Job Value:</div>
                                        <div class="val">$700</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Status:</div>
                                        <div class="val">complete</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Type:</div>
                                        <div class="val">Electrical; Building</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Sub Type:</div>
                                        <div class="val">Renovation</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Business Name:</div>
                                        <div class="val">David Mola</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Home Owner:</div>
                                        <div class="val">Pompei Family Revocabletrust</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Fees:</div>
                                        <div class="val">$25</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Permit Number:</div>
                                        <div class="val">EP-23-478</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Description:</div>
                                        <div class="val">N/A</div>
                                    </div>
                                                            </div>
                        </div>
                                            <div class="col">
                            <h4 class="r-h4">
                                <span class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/permit.svg" alt="" width="16" height="16">
                                </span>
                                <span class="txt">Permit #7</span>
                            </h4>
                            <div class="r-table">
                                                                    <div class="row">
                                        <div class="ttl">Effective Date:</div>
                                        <div class="val">07/22/2022</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Job Value:</div>
                                        <div class="val">$0</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Status:</div>
                                        <div class="val">issued</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Type:</div>
                                        <div class="val">Electrical; Building</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Sub Type:</div>
                                        <div class="val">200 amp service</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Business Name:</div>
                                        <div class="val">David Mola</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Home Owner:</div>
                                        <div class="val">Pompei Family Revocabletrust</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Fees:</div>
                                        <div class="val">$35</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Permit Number:</div>
                                        <div class="val">EP-22-667</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Description:</div>
                                        <div class="val">N/A</div>
                                    </div>
                                                            </div>
                        </div>
                                            <div class="col">
                            <h4 class="r-h4">
                                <span class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/permit.svg" alt="" width="16" height="16">
                                </span>
                                <span class="txt">Permit #8</span>
                            </h4>
                            <div class="r-table">
                                                                    <div class="row">
                                        <div class="ttl">Effective Date:</div>
                                        <div class="val">05/17/2021</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Job Value:</div>
                                        <div class="val">$6,140</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Status:</div>
                                        <div class="val">issued</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Type:</div>
                                        <div class="val">Building</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Sub Type:</div>
                                        <div class="val">Above ground pool</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Business Name:</div>
                                        <div class="val">N/A</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Home Owner:</div>
                                        <div class="val">Pompei Family Revocabletrust</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Fees:</div>
                                        <div class="val">$44</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Permit Number:</div>
                                        <div class="val">BP-21-665</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Description:</div>
                                        <div class="val">N/A</div>
                                    </div>
                                                            </div>
                        </div>
                                            <div class="col">
                            <h4 class="r-h4">
                                <span class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/permit.svg" alt="" width="16" height="16">
                                </span>
                                <span class="txt">Permit #9</span>
                            </h4>
                            <div class="r-table">
                                                                    <div class="row">
                                        <div class="ttl">Effective Date:</div>
                                        <div class="val">08/12/2015</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Job Value:</div>
                                        <div class="val">$9,800</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Status:</div>
                                        <div class="val">issued</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Type:</div>
                                        <div class="val">Building</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Sub Type:</div>
                                        <div class="val">Re-roof</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Business Name:</div>
                                        <div class="val">Vincent Colangelo</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Home Owner:</div>
                                        <div class="val">Pompei Joseph G</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Fees:</div>
                                        <div class="val">$59</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Permit Number:</div>
                                        <div class="val">BP-15-807</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Description:</div>
                                        <div class="val">N/A</div>
                                    </div>
                                                            </div>
                        </div>
                                            <div class="col">
                            <h4 class="r-h4">
                                <span class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/permit.svg" alt="" width="16" height="16">
                                </span>
                                <span class="txt">Permit #10</span>
                            </h4>
                            <div class="r-table">
                                                                    <div class="row">
                                        <div class="ttl">Effective Date:</div>
                                        <div class="val">12/15/2004</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Job Value:</div>
                                        <div class="val">N/A</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Status:</div>
                                        <div class="val">complete</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Type:</div>
                                        <div class="val">Electrical</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Sub Type:</div>
                                        <div class="val">Legacy data</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Business Name:</div>
                                        <div class="val">Addario, Steven J. Jr</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Home Owner:</div>
                                        <div class="val">Pompei Joseph G, Pompeiivy S</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Fees:</div>
                                        <div class="val">$25</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Permit Number:</div>
                                        <div class="val">GP-2004-0651</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Description:</div>
                                        <div class="val">N/A</div>
                                    </div>
                                                            </div>
                        </div>
                                            <div class="col">
                            <h4 class="r-h4">
                                <span class="icn">
                                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/permit.svg" alt="" width="16" height="16">
                                </span>
                                <span class="txt">Permit #11</span>
                            </h4>
                            <div class="r-table">
                                                                    <div class="row">
                                        <div class="ttl">Effective Date:</div>
                                        <div class="val">12/10/2004</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Job Value:</div>
                                        <div class="val">N/A</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Status:</div>
                                        <div class="val">complete</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Type:</div>
                                        <div class="val">Plumbing</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Sub Type:</div>
                                        <div class="val">Legacy data</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Business Name:</div>
                                        <div class="val">Addario, Steven J. Jr</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Home Owner:</div>
                                        <div class="val">Pompei Joseph G, Pompeiivy S</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Fees:</div>
                                        <div class="val">$25</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Permit Number:</div>
                                        <div class="val">PP-2004-0624</div>
                                    </div>
                                                                    <div class="row">
                                        <div class="ttl">Description:</div>
                                        <div class="val">N/A</div>
                                    </div>
                                                            </div>
                        </div>
                                    </div>
            </div>
        </div>
    </div>        
                                            </div>                            <div class="r-section" id="section_liens_and_judgments">                    <div class="hdr">
    <h2 class="r-h2">
        <span class="icn">
            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/32/gavel.svg" alt="" width="32" height="32">
        </span>
        <span class="txt">Liens & Judgments</span>
    </h2>
</div>
<div class="bdy">
    <!--Disclaimer-->
    <div class="notify">
    <div class="row">
        <div class="col">
            <div class="itm">
                <div class="icn filter-special">
                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/info.svg">
                </div>
                <div class="txt">Liens and Judgments information can vary in accuracy and may be subject to change over time due to updates or corrections made by relevant authorities. Additionally, please be aware that the address information used to match Lien and Judgment records may not always precisely match the property address.</div>
            </div>
        </div>
    </div>
</div>

            <div class="notify">
    <div class="row">
        <div class="col">
            <div class="itm">
                <div class="icn filter-special">
                    <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/info.svg">
                </div>
                <div class="txt">We could not locate any liens or judgments. It's important to keep in mind that the lack of information or results in our reports may not always be entirely accurate, comprehensive, or current. Therefore, we kindly advise against solely relying on our findings and recommend conducting your own thorough research.</div>
            </div>
        </div>
    </div>
</div>
    
    <!--Liens-->
    
    <!--Judgments-->
    </div>        
                                            </div>                            <div class="r-section" id="section_residents">                    
<div class="hdr">
    <h2 class="r-h2">
        <span class="icn">
            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/32/group.svg" alt="" width="32" height="32">
        </span>
        <span class="txt">Residents</span>
    </h2>
</div>
<div class="bdy">
            <div class="sec">
            <div class="blk">
                                    <h4 class="r-h3">
                        <span class="icn">
                            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/user.svg" alt="" width="16" height="16">
                        </span>
                        <span class="txt">
                                                            <a href="/members/person-search?firstName=JOSEPH&lastName=POMPEI&city=BILLERICA&state=MA" class="">Joseph Pompei</a>
                                                    </span>
                    </h4>
                                    <h4 class="r-h3">
                        <span class="icn">
                            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/user.svg" alt="" width="16" height="16">
                        </span>
                        <span class="txt">
                                                            <a href="/members/person-search?firstName=MARISA&lastName=POMPEI&city=BILLERICA&state=MA" class="">Marisa Pompei</a>
                                                    </span>
                    </h4>
                                    <h4 class="r-h3">
                        <span class="icn">
                            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/user.svg" alt="" width="16" height="16">
                        </span>
                        <span class="txt">
                                                            <a href="/members/person-search?firstName=IVY&lastName=POMPEI&city=BILLERICA&state=MA" class="">Ivy Pompei</a>
                                                    </span>
                    </h4>
                                    <h4 class="r-h3">
                        <span class="icn">
                            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/user.svg" alt="" width="16" height="16">
                        </span>
                        <span class="txt">
                                                            <a href="/members/person-search?firstName=ROBERTO&lastName=POMPEI&city=BILLERICA&state=MA" class="">Roberto Pompei</a>
                                                    </span>
                    </h4>
                                    <h4 class="r-h3">
                        <span class="icn">
                            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/user.svg" alt="" width="16" height="16">
                        </span>
                        <span class="txt">
                                                            <a href="/members/person-search?firstName=MICHAEL&lastName=PETERS&city=BILLERICA&state=MA" class="">Michael Peters</a>
                                                    </span>
                    </h4>
                            </div>
        </div>
    </div>        
                                            </div>                            <div class="r-section" id="section_images">                    <div class="hdr">
    <h2 class="r-h2">
        <span class="icn">
            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/32/images.svg" alt="" width="32" height="32">
        </span>
        <span class="txt">Images</span>
    </h2>
</div>
<div class="bdy">
    <div class="sec">
        <div class="r-imgs no-slick">
                            <div class="col">
                    <div class="cnt">
                        <img class="lazyload" data-src="https://ssl.cdn-redfin.com/photo/52/mbphoto/066/genMid.73310066_1.jpg" alt="" width="623" height="414">
                    </div>
                </div>
                            <div class="col">
                    <div class="cnt">
                        <img class="lazyload" data-src="https://ssl.cdn-redfin.com/photo/52/mbphoto/066/genMid.73310066_1_1.jpg" alt="" width="623" height="414">
                    </div>
                </div>
                            <div class="col">
                    <div class="cnt">
                        <img class="lazyload" data-src="https://ssl.cdn-redfin.com/photo/52/mbphoto/066/genMid.73310066_41_1.jpg" alt="" width="276" height="414">
                    </div>
                </div>
                    </div>
    </div>
</div>        
                                            </div>                            <div class="r-section" id="section_neighborhood">                    <div class="hdr">
    <h2 class="r-h2">
        <span class="icn">
            <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/32/pin_map.svg" alt="" width="32" height="32">
        </span>
        <span class="txt">Neighborhood</span>
    </h2>
</div>
<div class="bdy">
            <div class="sec">
            <h3 class="r-h3">
                <span class="icn-round">
                    <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/32/pin.svg" alt="" width="32" height="32">
                </span>
                <span class="txt">Area Information</span>
            </h3>
            <div class="r-cnt">
                <div class="row">
                    <div class="col">
                        <div class="blk">
                                                        <p>
                                                                    Situated strategically near major urban hubs, Billerica, MA, offers an enticing prospect for property investors. With a total population of 43,000 residents and an average age of 40, the community reflects a vibrant mix of generations. The racial demographics of Billerica include 75% White, 10% Asian, 7% Black, and 5% Hispanic, contributing to its rich cultural fabric. The average sale price for homes in Billerica is $450,000, while rentals are priced competitively at $2,000 per month. The estimated home value continues to appreciate steadily, promising solid returns on investment. In comparison to neighboring areas, Billerica properties are priced attractively, typically selling at 10-15% below the regional average. The area boasts several schools, ensuring access to quality education for residents. With a low crime rate, Billerica offers a safe and secure environment conducive to family living and business ventures. The average income is $85,000, fueling a noticeable upward trend in property acquisitions. In summary, Billerica, MA, presents a compelling investment opportunity marked by affordability, diversity, and promising growth prospects.                                                            </p>
                        </div>
                    </div>
                                    </div>
            </div>
        </div>
        <div class="sec">
        <div class="blk">
            <div class="niche-base">
                                    <div class="icn">
                        <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_plus.svg" alt="" width="76" height="76">
                    </div>
                                <div class="inf">
                    <h3 class="r-h3">
                        <span class="icn-round">
                            <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/32/city.svg" alt="" width="32" height="32">
                        </span>
                        <span class="txt">
                            Billerica, Massachusetts Ratings
                        </span>
                    </h3>
                </div>
            </div>
        </div>
                    <div class="niche-list2">
                <ul>
                                            <li>
                            <span class="icn">
                                                                    <img class="rat lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_plus.svg" alt="" width="76" height="76">
                                                                <img class="img lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/ratings/school.svg" alt="" width="32" height="32">
                            </span>
                            <span class="txt">Public Schools</span>
                        </li>
                                            <li>
                            <span class="icn">
                                                                    <img class="rat lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/c_plus.svg" alt="" width="76" height="76">
                                                                <img class="img lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/ratings/money.svg" alt="" width="32" height="32">
                            </span>
                            <span class="txt">Cost of Living</span>
                        </li>
                                            <li>
                            <span class="icn">
                                                                    <img class="rat lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/a_minus.svg" alt="" width="76" height="76">
                                                                <img class="img lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/ratings/health.svg" alt="" width="32" height="32">
                            </span>
                            <span class="txt">Health & Fitness</span>
                        </li>
                                            <li>
                            <span class="icn">
                                                                    <img class="rat lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/a.svg" alt="" width="76" height="76">
                                                                <img class="img lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/ratings/mountain.svg" alt="" width="32" height="32">
                            </span>
                            <span class="txt">Outdoor Activities</span>
                        </li>
                                            <li>
                            <span class="icn">
                                                                    <img class="rat lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_minus.svg" alt="" width="76" height="76">
                                                                <img class="img lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/ratings/discoball.svg" alt="" width="32" height="32">
                            </span>
                            <span class="txt">Nightlife</span>
                        </li>
                                            <li>
                            <span class="icn">
                                                                    <img class="rat lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/c_plus.svg" alt="" width="76" height="76">
                                                                <img class="img lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/ratings/weather.svg" alt="" width="32" height="32">
                            </span>
                            <span class="txt">Weather</span>
                        </li>
                                            <li>
                            <span class="icn">
                                                                    <img class="rat lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b.svg" alt="" width="76" height="76">
                                                                <img class="img lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/ratings/commute.svg" alt="" width="32" height="32">
                            </span>
                            <span class="txt">Commute</span>
                        </li>
                                            <li>
                            <span class="icn">
                                                                    <img class="rat lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_minus.svg" alt="" width="76" height="76">
                                                                <img class="img lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/ratings/bar.svg" alt="" width="32" height="32">
                            </span>
                            <span class="txt">Access to Bars</span>
                        </li>
                                            <li>
                            <span class="icn">
                                                                    <img class="rat lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_minus.svg" alt="" width="76" height="76">
                                                                <img class="img lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/ratings/coffee.svg" alt="" width="32" height="32">
                            </span>
                            <span class="txt">Access to Coffee Shops</span>
                        </li>
                                            <li>
                            <span class="icn">
                                                                    <img class="rat lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/a.svg" alt="" width="76" height="76">
                                                                <img class="img lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/ratings/kayak.svg" alt="" width="32" height="32">
                            </span>
                            <span class="txt">Access to Outdoor Activities</span>
                        </li>
                                            <li>
                            <span class="icn">
                                                                    <img class="rat lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/a_minus.svg" alt="" width="76" height="76">
                                                                <img class="img lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/ratings/park.svg" alt="" width="32" height="32">
                            </span>
                            <span class="txt">Access to Parks</span>
                        </li>
                                            <li>
                            <span class="icn">
                                                                    <img class="rat lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_minus.svg" alt="" width="76" height="76">
                                                                <img class="img lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/ratings/restaurant.svg" alt="" width="32" height="32">
                            </span>
                            <span class="txt">Access to Restaurants</span>
                        </li>
                                            <li>
                            <span class="icn">
                                                                    <img class="rat lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_minus.svg" alt="" width="76" height="76">
                                                                <img class="img lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/ratings/badge.svg" alt="" width="32" height="32">
                            </span>
                            <span class="txt">Crime & Safety</span>
                        </li>
                                            <li>
                            <span class="icn">
                                                                    <img class="rat lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b.svg" alt="" width="76" height="76">
                                                                <img class="img lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/ratings/diversity.svg" alt="" width="32" height="32">
                            </span>
                            <span class="txt">Diversity</span>
                        </li>
                                            <li>
                            <span class="icn">
                                                                    <img class="rat lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/a_minus.svg" alt="" width="76" height="76">
                                                                <img class="img lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/ratings/family.svg" alt="" width="32" height="32">
                            </span>
                            <span class="txt">Good for Families</span>
                        </li>
                                            <li>
                            <span class="icn">
                                                                    <img class="rat lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_minus.svg" alt="" width="76" height="76">
                                                                <img class="img lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/ratings/oldman.svg" alt="" width="32" height="32">
                            </span>
                            <span class="txt">Good for Retirees</span>
                        </li>
                                            <li>
                            <span class="icn">
                                                                    <img class="rat lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b.svg" alt="" width="76" height="76">
                                                                <img class="img lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/ratings/young.svg" alt="" width="32" height="32">
                            </span>
                            <span class="txt">Good for Young Professionals</span>
                        </li>
                                            <li>
                            <span class="icn">
                                                                    <img class="rat lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_minus.svg" alt="" width="76" height="76">
                                                                <img class="img lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/ratings/houses.svg" alt="" width="32" height="32">
                            </span>
                            <span class="txt">Housing</span>
                        </li>
                                            <li>
                            <span class="icn">
                                                                    <img class="rat lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_plus.svg" alt="" width="76" height="76">
                                                                <img class="img lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/ratings/suitcase.svg" alt="" width="32" height="32">
                            </span>
                            <span class="txt">Jobs</span>
                        </li>
                                            <li>
                            <span class="icn">
                                                                    <img class="rat lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/c_plus.svg" alt="" width="76" height="76">
                                                                <img class="img lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/ratings/walking.svg" alt="" width="32" height="32">
                            </span>
                            <span class="txt">Walkability</span>
                        </li>
                                    </ul>
            </div>
            <div class="powered-by">
    <div class="tx">Powered by</div>
    <div class="im">
        <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/niche.svg" alt="" width="141" height="22">
    </div>
</div>            </div>
    <div class="sec">
        <div class="blk">
            <div class="niche-base">
                                    <div class="icn">
                        <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_minus.svg" alt="" width="76" height="76">
                    </div>
                                <div class="inf">
                    <h3 class="r-h3">
                        <span class="icn-round">
                            <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/32/housing.svg" alt="" width="32" height="32">
                        </span>
                        <span class="txt">Housing</span>
                    </h3>
                </div>
            </div>
        </div>
                    <div class="r-list">
                <div class="row">
                                            <div class="col">
                            <div class="icn">
                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/home_dollar.svg" alt="" width="16" height="16">
                            </div>
                            <div class="txt">
                                <div class="ttl">Median Home Value:</div>
                                <div class="val">$532,600</div>
                            </div>
                        </div>
                                            <div class="col">
                            <div class="icn">
                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/tag.svg" alt="" width="16" height="16">
                            </div>
                            <div class="txt">
                                <div class="ttl">Median Rent:</div>
                                <div class="val">$2,104</div>
                            </div>
                        </div>
                                            <div class="col">
                            <div class="icn">
                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/home_percent.svg" alt="" width="16" height="16">
                            </div>
                            <div class="txt">
                                <div class="ttl">Median Real Estate Tax:</div>
                                <div class="val">1.11%</div>
                            </div>
                        </div>
                                            <div class="col">
                            <div class="icn">
                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/key.svg" alt="" width="16" height="16">
                            </div>
                            <div class="txt">
                                <div class="ttl">Rent vs. Own:</div>
                                <div class="val">80%</div>
                            </div>
                        </div>
                                    </div>
            </div>
                            <div class="r-charts">
                                    <div class="row">
                        <figure class="highcharts-figure">
                            <div id="commuteMethod"></div>
                        </figure>
                    </div>
                                    <div class="row">
                        <figure class="highcharts-figure">
                            <div id="commuteTime"></div>
                        </figure>
                    </div>
                                    <div class="row">
                        <figure class="highcharts-figure">
                            <div id="homeAgeBreakdown"></div>
                        </figure>
                    </div>
                                    <div class="row">
                        <figure class="highcharts-figure">
                            <div id="homeSizeBreakdown"></div>
                        </figure>
                    </div>
                                    <div class="row">
                        <figure class="highcharts-figure">
                            <div id="homeTypeBreakdowns"></div>
                        </figure>
                    </div>
                            </div>
            </div>
    <div class="sec">
        <div class="blk">
            <div class="niche-base">
                                    <div class="icn">
                        <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_minus.svg" alt="" width="76" height="76">
                    </div>
                                <div class="inf">
                    <h3 class="r-h3">
                        <span class="icn-round">
                            <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/32/police.svg" alt="" width="32" height="32">
                        </span>
                        <span class="txt">
                            Crime &amp; Safety
                            <small>Based on violent and property crime rates.</small>
                        </span>
                    </h3>
                </div>
            </div>

        </div>
<!--        <div class="blk">-->
<!--            <h3 class="r-h4">-->
<!--                <span class="txt">Violent Crimes <small>(calculated annually per 100,000 residents)</small></span>-->
<!--            </h3>-->
<!--            --><!--                <div class="r-list">-->
<!--                    <div class="row">-->
<!--                        --><!--                            <div class="col">-->
<!--                                <div class="icn">-->
<!--                                    <img class="lazyloaded" data-src="--><!--" src="--><!--" alt="" width="16" height="16">-->
<!--                                </div>-->
<!--                                <div class="txt">-->
<!--                                    <div class="ttl">--><!--:</div>-->
<!--                                    <div class="val">--><!--</div>-->
<!--                                    <div class="nte">--><!--</div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        --><!--                    </div>-->
<!--                </div>-->
<!--            --><!--        </div>-->
<!--        <div class="blk">-->
<!--            <h3 class="r-h4">-->
<!--                <span class="txt">Property Crimes <small>(calculated annually per 100,000 residents)</small></span>-->
<!--            </h3>-->
<!--            --><!--                <div class="r-list">-->
<!--                    <div class="row">-->
<!--                        --><!--                            <div class="col">-->
<!--                                <div class="icn">-->
<!--                                    <img class="lazyloaded" data-src="--><!--" src="--><!--" alt="" width="16" height="16">-->
<!--                                </div>-->
<!--                                <div class="txt">-->
<!--                                    <div class="ttl">--><!--:</div>-->
<!--                                    <div class="val">--><!--</div>-->
<!--                                    <div class="nte">--><!--</div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        --><!--                    </div>-->
<!--                </div>-->
<!--            --><!--        </div>-->
    </div>
    <div class="sec">
        <div class="blk">
            <div class="niche-base">
                                    <div class="icn">
                        <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_plus.svg" alt="" width="76" height="76">
                    </div>
                                <div class="inf">
                    <h3 class="r-h3">
                        <span class="icn-round">
                            <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/32/suitcase.svg" alt="" width="32" height="32">
                        </span>
                        <span class="txt">
                            Jobs
                            <small>Based on employment rates, job and business growth, and cost of living.</small>
                        </span>
                    </h3>
                </div>
            </div>
        </div>
                    <div class="r-charts">
                                    <div class="row">
                                                    <div class="r-med">
                                                                    <div class="t1">Median Household Income</div>
                                                                                                    <div class="t2">$138,397</div>
                                                                                                    <div class="t3">National N/A</div>
                                                            </div>
                                                                    </div>
                                    <div class="row">
                                                                            <figure class="highcharts-figure">
                                <div id="populationAgeBreakdown"></div>
                            </figure>
                                            </div>
                            </div>
            </div>

    <div class="sec">
        <div class="blk">
            <div class="niche-base">
                <!--                -->                <!--                    <div class="icn">-->
                <!--                        <img class="lazyload" data-src="--><!--" alt="" width="76" height="76">-->
                <!--                    </div>-->
                <!--                -->                <div class="inf">
                    <h3 class="r-h3">
                        <span class="icn-round">
                            <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/32/education.svg" alt="" width="32" height="32">
                        </span>
                        <span class="txt">Education Breakdown</span>
                    </h3>
                </div>

            </div>
        </div>

                    <div class="r-charts">
                                    <div class="row">
                        <figure class="highcharts-figure">
                            <div id="educationBreakdown"></div>
                        </figure>
                    </div>
                            </div>
                <!--SCHOOLS-->
                <div class="schools">
                            <div class="res">
                    <div class="niche-base">
                                                <div class="inf">
                            <h4 class="r-h3">Billerica Knowledge Beginnings</h4>
                            <div class="txt">
                                <ul>
                                    <li>
                                        <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/pin.svg" alt="" width="16" height="16">
                                        262 CONCORD RD, BILLERICA, MA 01821                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                                    </div>
                            <div class="res">
                    <div class="niche-base">
                                                    <div class="icn">
                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b.svg" alt="" width="76" height="76">
                            </div>
                                                <div class="inf">
                            <h4 class="r-h3">Billerica Memorial High School</h4>
                            <div class="txt">
                                <ul>
                                    <li>
                                        <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/pin.svg" alt="" width="16" height="16">
                                        35 RIVER ST, BILLERICA, MA 01821                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                                            <div class="niche-list-inline">
                            <ul>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_minus.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Academics</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/a_minus.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Teachers</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/a.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Clubs & Activities</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/a.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Sports</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_plus.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Resources & Facilities</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_plus.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Diversity</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_minus.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">College Prep</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/a.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Administration</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_minus.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Food</span>
                                    </li>
                                                            </ul>
                        </div>
                                    </div>
                            <div class="res">
                    <div class="niche-base">
                                                    <div class="icn">
                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_plus.svg" alt="" width="76" height="76">
                            </div>
                                                <div class="inf">
                            <h4 class="r-h3">John F. Kennedy School</h4>
                            <div class="txt">
                                <ul>
                                    <li>
                                        <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/pin.svg" alt="" width="16" height="16">
                                        20 KIMBROUGH RD, BILLERICA, MA 01821                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                                            <div class="niche-list-inline">
                            <ul>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_plus.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Academics</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/a_minus.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Teachers</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_minus.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Diversity</span>
                                    </li>
                                                            </ul>
                        </div>
                                    </div>
                            <div class="res">
                    <div class="niche-base">
                                                    <div class="icn">
                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/a_minus.svg" alt="" width="76" height="76">
                            </div>
                                                <div class="inf">
                            <h4 class="r-h3">John Parker Elementary School</h4>
                            <div class="txt">
                                <ul>
                                    <li>
                                        <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/pin.svg" alt="" width="16" height="16">
                                        52 RIVER ST, BILLERICA, MA 01821                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                                            <div class="niche-list-inline">
                            <ul>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_plus.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Academics</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/a.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Teachers</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/a_minus.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Diversity</span>
                                    </li>
                                                            </ul>
                        </div>
                                    </div>
                            <div class="res">
                    <div class="niche-base">
                                                    <div class="icn">
                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_plus.svg" alt="" width="76" height="76">
                            </div>
                                                <div class="inf">
                            <h4 class="r-h3">Locke Middle School</h4>
                            <div class="txt">
                                <ul>
                                    <li>
                                        <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/pin.svg" alt="" width="16" height="16">
                                        110 ALLEN RD, BILLERICA, MA 01821                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                                            <div class="niche-list-inline">
                            <ul>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_plus.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Academics</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/a_minus.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Teachers</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Diversity</span>
                                    </li>
                                                            </ul>
                        </div>
                                    </div>
                            <div class="res">
                    <div class="niche-base">
                                                    <div class="icn">
                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b.svg" alt="" width="76" height="76">
                            </div>
                                                <div class="inf">
                            <h4 class="r-h3">Marshall Middle School</h4>
                            <div class="txt">
                                <ul>
                                    <li>
                                        <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/pin.svg" alt="" width="16" height="16">
                                        15 FLOYD ST, BILLERICA, MA 01821                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                                            <div class="niche-list-inline">
                            <ul>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Academics</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/a_minus.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Teachers</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_plus.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Diversity</span>
                                    </li>
                                                            </ul>
                        </div>
                                    </div>
                            <div class="res">
                    <div class="niche-base">
                                                    <div class="icn">
                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b.svg" alt="" width="76" height="76">
                            </div>
                                                <div class="inf">
                            <h4 class="r-h3">Shawsheen Valley Technical High School</h4>
                            <div class="txt">
                                <ul>
                                    <li>
                                        <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/pin.svg" alt="" width="16" height="16">
                                        100 COOK ST, BILLERICA, MA 01821                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                                            <div class="niche-list-inline">
                            <ul>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Academics</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/a.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Teachers</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Clubs & Activities</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_plus.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Sports</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/a_minus.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Resources & Facilities</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/c_plus.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Diversity</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/c.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">College Prep</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_minus.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Administration</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/a_minus.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Food</span>
                                    </li>
                                                            </ul>
                        </div>
                                    </div>
                            <div class="res">
                    <div class="niche-base">
                                                    <div class="icn">
                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_plus.svg" alt="" width="76" height="76">
                            </div>
                                                <div class="inf">
                            <h4 class="r-h3">Thomas Ditson Elementary School</h4>
                            <div class="txt">
                                <ul>
                                    <li>
                                        <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/pin.svg" alt="" width="16" height="16">
                                        39 COOK ST, BILLERICA, MA 01821                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                                            <div class="niche-list-inline">
                            <ul>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_plus.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Academics</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/a_minus.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Teachers</span>
                                    </li>
                                                                    <li>
                                                                                    <span class="icn">
                                                <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b_plus.svg" alt="" width="76" height="76">
                                            </span>
                                                                                <span class="txt">Diversity</span>
                                    </li>
                                                            </ul>
                        </div>
                                    </div>
                    </div>
            </div>
    <div class="sec">
        <div class="blk">
            <div class="niche-base">
                                    <div class="icn">
                        <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/niche2/b.svg" alt="" width="76" height="76">
                    </div>
                                <div class="inf">
                    <h3 class="r-h3">
                        <span class="icn-round">
                            <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/32/people.svg" alt="" width="32" height="32">
                        </span>
                        <span class="txt">
                            Diversity
                            <small>Based on ethnic and economic diversity.</small>
                        </span>
                    </h3>
                </div>
            </div>
        </div>
                    <div class="r-list">
                <div class="row">
                                            <div class="col">
                            <div class="icn">
                                <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/group.svg" alt="" width="16" height="16">
                            </div>
                            <div class="txt">
                                <div class="ttl">Population:</div>
                                <div class="val">30,854</div>
                            </div>
                        </div>
                                            <div class="col">
                            <div class="icn">
                                <img src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/16/suitcase_no.svg" alt="" width="16" height="16">
                            </div>
                            <div class="txt">
                                <div class="ttl">Unemployment Rate:</div>
                                <div class="val">2.29%</div>
                            </div>
                        </div>
                                    </div>
            </div>
                            <div class="r-charts">
                <div class="row">
                    <figure class="highcharts-figure">
                        <div id="diversityBreakdown"></div>
                    </figure>
                </div>
            </div>
                <div class="powered-by">
    <div class="tx">Powered by</div>
    <div class="im">
        <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/niche.svg" alt="" width="141" height="22">
    </div>
</div>    </div>
</div>

<script>
    /**
     * init highcharts
     * @param charts
     */
    function initCharts(charts) {
        Object.values(charts).forEach((chartData) => {
            if (!chartData) {
                return;
            }

            let chartOptions = {
                chart: {
                    type: chartData['type'],
                },
                title: {
                    text: chartData['title'],
                },
                series: chartData['series'],
                yAxis: {
                    title: {
                        text: chartData['title'],
                    }
                },
            };

            Highcharts.chart(chartData['id'], chartOptions);
        })
    }

    let housingCharts = [{"id":"commuteMethod","type":"column","title":"Commute Method","series":[{"data":[{"name":"Bicycle","y":0},{"name":"Carpooled","y":7.477},{"name":"Drove Alone","y":74.19},{"name":"Public Transportation","y":1.719},{"name":"Motorcycle","y":0},{"name":"Taxicab","y":0.049},{"name":"Walked","y":0.622},{"name":"Worked at Home","y":15.1},{"name":"Other","y":0.843}]}]},{"id":"commuteTime","type":"column","title":"Commute Time (in Minutes)","series":[{"data":[{"name":"Under 5","y":2.191},{"name":"5 - 9","y":6.572},{"name":"10 - 14","y":12.468},{"name":"15 - 19","y":14.391000000000002},{"name":"20 - 24","y":13.347000000000001},{"name":"25 - 29","y":7.183000000000001},{"name":"30 - 34","y":16.442},{"name":"35 - 39","y":3.948},{"name":"40 - 44","y":5.75},{"name":"45 - 59","y":8.991},{"name":"60 - 89","y":6.1129999999999995},{"name":"Over 90","y":2.604}]}]},{"id":"homeAgeBreakdown","type":"column","title":"Home Age Breakdown","series":[{"data":[{"name":"Before 1939","y":11.456},{"name":"1940 - 1959","y":11.26},{"name":"1960 - 1979","y":42.719},{"name":"1980 - 1999","y":18.741},{"name":"2000 - Present","y":15.824}]}]},{"id":"homeSizeBreakdown","type":"column","title":"Home Size Breakdown","series":[{"data":[{"name":"No Bedrooms","y":0.827},{"name":"1 Bedroom","y":8.146},{"name":"2 Bedrooms","y":22.017},{"name":"3 Bedrooms","y":43.53},{"name":"4 Bedrooms","y":19.543},{"name":"5 or More Bedrooms","y":5.937}]}]},{"id":"homeTypeBreakdowns","type":"column","title":"Home Type Breakdowns","series":[{"data":[{"name":"Large Apartment Building","y":14.109},{"name":"Mobile Home","y":0.281},{"name":"Single Family","y":74.077},{"name":"Small Apartment Building","y":5.34},{"name":"Townhouse","y":6.193}]}]}];
    let jobsCharts = [null,{"id":"populationAgeBreakdown","type":"column","title":"Population Age Breakdown","series":[{"data":[{"name":"Under 10","y":9.577},{"name":"10 - 17","y":9.594},{"name":"18 - 24","y":7.808},{"name":"25 - 34","y":13.719000000000001},{"name":"35 - 44","y":12.439},{"name":"45 - 54","y":14.935},{"name":"55 - 64","y":15.797},{"name":"65 Plus","y":16.131}]}]}];
    let educationCharts = {"educationBreakdown":{"id":"educationBreakdown","type":"column","title":"Education Breakdown","series":[{"data":[{"name":"Below High School","y":0.8789999999999999},{"name":"Nursery Grade 4","y":0},{"name":"Grade 5-6","y":0.377},{"name":"Grade 7-8","y":0.5459999999999999},{"name":"Grade 9","y":0.5329999999999999},{"name":"Grade 10","y":0.679},{"name":"Grade 11","y":0.41700000000000004},{"name":"Grade 12","y":0.954},{"name":"High School","y":24.66},{"name":"College Less","y":5.428},{"name":"Some College","y":11.664},{"name":"Associate Degree","y":10.581},{"name":"Bachelor Degree","y":25.482},{"name":"Masters Degree","y":14.709},{"name":"Professional Degree","y":1.2919999999999998},{"name":"Doctorate","y":1.704}]}]}};
    let $diversityCharts = [{"id":"diversityBreakdown","type":"column","title":"Diversity Breakdown","series":[{"data":[{"name":"African-American","y":4.913},{"name":"Asian","y":8.978},{"name":"Hispanic","y":3.9350000000000005},{"name":"Multiracial","y":2.5989999999999998},{"name":"Native American","y":0},{"name":"Pacific Islander","y":0.104},{"name":"White","y":79.092},{"name":"Other","y":0.379}]}]}];
    document.addEventListener('DOMContentLoaded', () => {
        initCharts(housingCharts);
        initCharts(jobsCharts);
        initCharts(educationCharts);
        initCharts($diversityCharts);
    });
</script>

<style>
    .niche-base-txt {
        color: #7A7C83;
        font-size: 14px;
    }
</style>        
                                            </div>                            <div class="r-section" id="section_sex_offenders">                    <div class="hdr">
    <h2 class="r-h2">
        <span class="icn">
            <img class="lazyload" data-src="/tspecV2/PremiumPropertyReport/shared/img/icns/fill/32/avatar.svg" alt="" width="32" height="32">
        </span>
        <span class="txt">Sex Offenders</span>
    </h2>
</div>
<div class="bdy">
        <div class="sec">
        <div class="r-res-list" id="sexOffendersListContainer">
                        <div class="row acc ">
                <div class="head acc-head">
                    <div class="img">
                        <img class="lazyload" data-src="https://storage.googleapis.com/sex-offenders-images-v3/MA526845.jpeg" alt="" width="96" height="96">
                    </div>
                    <div class="inf">
                        <div class="t1">PHILIP CLARK</div>
                        <div class="t2"><strong>Offense:</strong> 2/21/2019-Rape</div>
<!--                        <div class="t3">--><!--</div>-->
                    </div>
                    <div class="cta">
                        <span class="btn sm special">Full Report</span>
                    </div>
                </div>
                <div class="body acc-body">
                    <div class="r-table two-cols">
                                                    <div class="row">
                                <div class="ttl">Name:</div>
                                <div class="val">PHILIP CLARK</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Gender:</div>
                                <div class="val">Male</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Date of Birth:</div>
                                <div class="val">January 01, 1994</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Race:</div>
                                <div class="val">White</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Height:</div>
                                <div class="val">6ft 1in</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Weight:</div>
                                <div class="val">215</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Eye:</div>
                                <div class="val">Hazel</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Hair:</div>
                                <div class="val">Brown</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Age:</div>
                                <div class="val">30</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Scars Marks:</div>
                                <div class="val">No markings found.</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Address:</div>
                                <div class="val">379 BOSTON RD, BILLERICA, MA 01821</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">State:</div>
                                <div class="val">MA</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Location:</div>
                                <div class="val">BILLERICA</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">ZipCode:</div>
                                <div class="val">01821</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Aliases:</div>
                                <div class="val">No known aliases</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Changes:</div>
                                <div class="val">Home-379 BOSTON RD, BILLERICA, MA 01821</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Predator:</div>
                                <div class="val">false</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Absconder:</div>
                                <div class="val">false</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Last Updated:</div>
                                <div class="val">October 25, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Created:</div>
                                <div class="val">June 28, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Last Synced:</div>
                                <div class="val">October 16, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Synced:</div>
                                <div class="val"></div>
                            </div>
                                            </div>
                </div>
            </div>
                        <div class="row acc ">
                <div class="head acc-head">
                    <div class="img">
                        <img class="lazyload" data-src="https://storage.googleapis.com/sex-offenders-images-v3/MA529319.jpeg" alt="" width="96" height="96">
                    </div>
                    <div class="inf">
                        <div class="t1">NICHOLAS HAMPTON</div>
                        <div class="t2"><strong>Offense:</strong> 7/12/2023-Indecent assault and battery on a person aged 14 or older</div>
<!--                        <div class="t3">--><!--</div>-->
                    </div>
                    <div class="cta">
                        <span class="btn sm special">Full Report</span>
                    </div>
                </div>
                <div class="body acc-body">
                    <div class="r-table two-cols">
                                                    <div class="row">
                                <div class="ttl">Name:</div>
                                <div class="val">NICHOLAS HAMPTON</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Gender:</div>
                                <div class="val">Male</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Date of Birth:</div>
                                <div class="val">January 01, 1995</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Race:</div>
                                <div class="val">White</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Height:</div>
                                <div class="val">5ft 11in</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Weight:</div>
                                <div class="val">175</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Eye:</div>
                                <div class="val">Green</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Hair:</div>
                                <div class="val">Brown</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Age:</div>
                                <div class="val">29</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Scars Marks:</div>
                                <div class="val">No markings found.</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Address:</div>
                                <div class="val">37 CONNOLLY RD, BILLERICA, MA 01821</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">State:</div>
                                <div class="val">MA</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Location:</div>
                                <div class="val">BILLERICA</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">ZipCode:</div>
                                <div class="val">01821</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Aliases:</div>
                                <div class="val">No known aliases</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Changes:</div>
                                <div class="val">Home-37 CONNOLLY RD, BILLERICA, MA 01821;Unknown-37 CONNOLLY RD, BILLERICA, MA 01821</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Predator:</div>
                                <div class="val">false</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Absconder:</div>
                                <div class="val">false</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Last Updated:</div>
                                <div class="val">October 16, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Created:</div>
                                <div class="val">June 28, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Last Synced:</div>
                                <div class="val">November 19, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Synced:</div>
                                <div class="val">1</div>
                            </div>
                                            </div>
                </div>
            </div>
                        <div class="row acc ">
                <div class="head acc-head">
                    <div class="img">
                        <img class="lazyload" data-src="https://storage.googleapis.com/sex-offenders-images-v3/MA524142.jpeg" alt="" width="96" height="96">
                    </div>
                    <div class="inf">
                        <div class="t1">CHRISTOPHER W HATHAWAY</div>
                        <div class="t2"><strong>Offense:</strong> 4/6/2016-Open and gross lewdness and lascivious behavior;10/27/2015-Open and gross lewdness and lascivious behavior</div>
<!--                        <div class="t3">--><!--</div>-->
                    </div>
                    <div class="cta">
                        <span class="btn sm special">Full Report</span>
                    </div>
                </div>
                <div class="body acc-body">
                    <div class="r-table two-cols">
                                                    <div class="row">
                                <div class="ttl">Name:</div>
                                <div class="val">CHRISTOPHER W HATHAWAY</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Gender:</div>
                                <div class="val">Male</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Date of Birth:</div>
                                <div class="val">January 01, 1984</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Race:</div>
                                <div class="val">White</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Height:</div>
                                <div class="val">5ft 10in</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Weight:</div>
                                <div class="val">130</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Eye:</div>
                                <div class="val">Brown</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Hair:</div>
                                <div class="val">Brown</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Age:</div>
                                <div class="val">40</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Scars Marks:</div>
                                <div class="val">No markings found.</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Address:</div>
                                <div class="val">16 RIVER ST, APT A, BILLERICA, MA 01821</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">State:</div>
                                <div class="val">MA</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Location:</div>
                                <div class="val">BILLERICA</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">ZipCode:</div>
                                <div class="val">01821</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Aliases:</div>
                                <div class="val">No known aliases</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Changes:</div>
                                <div class="val">Home-16 RIVER ST, APT A, BILLERICA, MA 01821</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Predator:</div>
                                <div class="val">false</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Absconder:</div>
                                <div class="val">false</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Last Updated:</div>
                                <div class="val">June 28, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Created:</div>
                                <div class="val">June 28, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Last Synced:</div>
                                <div class="val">November 19, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Synced:</div>
                                <div class="val">1</div>
                            </div>
                                            </div>
                </div>
            </div>
                        <div class="row acc ">
                <div class="head acc-head">
                    <div class="img">
                        <img class="lazyload" data-src="https://storage.googleapis.com/sex-offenders-images-v3/MA451566.jpeg" alt="" width="96" height="96">
                    </div>
                    <div class="inf">
                        <div class="t1">DO KYUN KIM</div>
                        <div class="t2"><strong>Offense:</strong> 11/8/2013-Rape</div>
<!--                        <div class="t3">--><!--</div>-->
                    </div>
                    <div class="cta">
                        <span class="btn sm special">Full Report</span>
                    </div>
                </div>
                <div class="body acc-body">
                    <div class="r-table two-cols">
                                                    <div class="row">
                                <div class="ttl">Name:</div>
                                <div class="val">DO KYUN KIM</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Gender:</div>
                                <div class="val">Male</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Date of Birth:</div>
                                <div class="val">January 01, 1993</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Race:</div>
                                <div class="val">Asian/Pacific Islander</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Height:</div>
                                <div class="val">5ft 10in</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Weight:</div>
                                <div class="val">140</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Eye:</div>
                                <div class="val">Brown</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Hair:</div>
                                <div class="val">Black</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Age:</div>
                                <div class="val">31</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Scars Marks:</div>
                                <div class="val">No markings found.</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Address:</div>
                                <div class="val">847 BOSTON RD, BILLERICA, MA 01821</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">State:</div>
                                <div class="val">MA</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Location:</div>
                                <div class="val">BILLERICA</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">ZipCode:</div>
                                <div class="val">01821</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Aliases:</div>
                                <div class="val">No known aliases</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Changes:</div>
                                <div class="val">Home-847 BOSTON RD, BILLERICA, MA 01821;Unknown-375 MOUNT VERNON ST, APT 215, BOSTON, MA 02125;Unknown-847 BOSTON RD, BILLERICA, MA 01821</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Predator:</div>
                                <div class="val">false</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Absconder:</div>
                                <div class="val">false</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Last Updated:</div>
                                <div class="val">June 28, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Created:</div>
                                <div class="val">June 28, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Last Synced:</div>
                                <div class="val">November 19, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Synced:</div>
                                <div class="val">1</div>
                            </div>
                                            </div>
                </div>
            </div>
                        <div class="row acc ">
                <div class="head acc-head">
                    <div class="img">
                        <img class="lazyload" data-src="https://storage.googleapis.com/sex-offenders-images-v3/FL111711.jpeg" alt="" width="96" height="96">
                    </div>
                    <div class="inf">
                        <div class="t1">DO KYUN KIM</div>
                        <div class="t2"><strong>Offense:</strong> 11/8/2013-SEX OFFENSE, OTHER STATE (2 COUNTS OF RAPE)</div>
<!--                        <div class="t3">--><!--</div>-->
                    </div>
                    <div class="cta">
                        <span class="btn sm special">Full Report</span>
                    </div>
                </div>
                <div class="body acc-body">
                    <div class="r-table two-cols">
                                                    <div class="row">
                                <div class="ttl">Name:</div>
                                <div class="val">DO KYUN KIM</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Gender:</div>
                                <div class="val">Male</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Date of Birth:</div>
                                <div class="val">May 18, 1993</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Race:</div>
                                <div class="val">Asian/Pacific Islander</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Height:</div>
                                <div class="val">5ft 10in</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Weight:</div>
                                <div class="val">140</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Eye:</div>
                                <div class="val">Brown</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Hair:</div>
                                <div class="val">Black</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Age:</div>
                                <div class="val">31</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Scars Marks:</div>
                                <div class="val">Tattoo - Left Hand;Tattoo - Left Shoulder</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Address:</div>
                                <div class="val">Last Reported Address - Out of State, Billerica, MA 01821-6143</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">State:</div>
                                <div class="val">MA</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Location:</div>
                                <div class="val">Billerica</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">ZipCode:</div>
                                <div class="val">01821</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Aliases:</div>
                                <div class="val">Do Kim;Dokyun Kim;Eddie Kim</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Changes:</div>
                                <div class="val">Home-Last Reported Address - Out of State, Billerica, MA 01821-6143</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Predator:</div>
                                <div class="val">false</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Absconder:</div>
                                <div class="val">false</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Last Updated:</div>
                                <div class="val">June 28, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Created:</div>
                                <div class="val">June 28, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Last Synced:</div>
                                <div class="val">November 19, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Synced:</div>
                                <div class="val">1</div>
                            </div>
                                            </div>
                </div>
            </div>
                        <div class="row acc ">
                <div class="head acc-head">
                    <div class="img">
                        <img class="lazyload" data-src="https://storage.googleapis.com/sex-offenders-images-v3/FL1811.jpeg" alt="" width="96" height="96">
                    </div>
                    <div class="inf">
                        <div class="t1">Kyler Sean Coleman</div>
                        <div class="t2"><strong>Offense:</strong> 9/20/1996-LEWD,LASCIVIOUS CHILD U/16; F.S. 800.04 (PRINCIPAL)</div>
<!--                        <div class="t3">--><!--</div>-->
                    </div>
                    <div class="cta">
                        <span class="btn sm special">Full Report</span>
                    </div>
                </div>
                <div class="body acc-body">
                    <div class="r-table two-cols">
                                                    <div class="row">
                                <div class="ttl">Name:</div>
                                <div class="val">Kyler Sean Coleman</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Gender:</div>
                                <div class="val">Male</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Date of Birth:</div>
                                <div class="val">October 26, 1974</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Race:</div>
                                <div class="val">White</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Height:</div>
                                <div class="val">5ft 10in</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Weight:</div>
                                <div class="val">172</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Eye:</div>
                                <div class="val">Brown</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Hair:</div>
                                <div class="val">Brown</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Age:</div>
                                <div class="val">50</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Scars Marks:</div>
                                <div class="val">Tattoo - Right Arm;Tattoo - Neck;Tattoo - Left Arm;Tattoo - Left Shoulder;Birthmark - Right Leg;Tattoo - Left Chest</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Address:</div>
                                <div class="val">Last Reported Address - Out of State, Billerica, MA 01821</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">State:</div>
                                <div class="val">MA</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Location:</div>
                                <div class="val">Billerica</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">ZipCode:</div>
                                <div class="val">01821</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Aliases:</div>
                                <div class="val">Kyler Coleman;KYLER SEAN COLEMAN SR;KYLER S COLEMAN</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Changes:</div>
                                <div class="val">Home-Last Reported Address - Out of State, Billerica, MA 01821</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Predator:</div>
                                <div class="val">false</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Absconder:</div>
                                <div class="val">false</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Last Updated:</div>
                                <div class="val">June 28, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Created:</div>
                                <div class="val">June 28, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Last Synced:</div>
                                <div class="val">November 19, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Synced:</div>
                                <div class="val">1</div>
                            </div>
                                            </div>
                </div>
            </div>
                        <div class="row acc ">
                <div class="head acc-head">
                    <div class="img">
                        <img class="lazyload" data-src="https://storage.googleapis.com/sex-offenders-images-v3/WI20316.jpeg" alt="" width="96" height="96">
                    </div>
                    <div class="inf">
                        <div class="t1">JAMES LAND</div>
                        <div class="t2"><strong>Offense:</strong> 8/15/2005-Third-Degree Sexual Assault</div>
<!--                        <div class="t3">--><!--</div>-->
                    </div>
                    <div class="cta">
                        <span class="btn sm special">Full Report</span>
                    </div>
                </div>
                <div class="body acc-body">
                    <div class="r-table two-cols">
                                                    <div class="row">
                                <div class="ttl">Name:</div>
                                <div class="val">JAMES LAND</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Gender:</div>
                                <div class="val">Male</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Date of Birth:</div>
                                <div class="val">unknown</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Race:</div>
                                <div class="val">White</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Height:</div>
                                <div class="val">6ft  0in</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Weight:</div>
                                <div class="val">260</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Eye:</div>
                                <div class="val">Blue</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Hair:</div>
                                <div class="val">Brown</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Age:</div>
                                <div class="val">41</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Scars Marks:</div>
                                <div class="val">No markings found.</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Address:</div>
                                <div class="val">12 WOODBINE RD, BILLERICA, MA 01821-2319</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">State:</div>
                                <div class="val">MA</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Location:</div>
                                <div class="val">BILLERICA</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">ZipCode:</div>
                                <div class="val">01821</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Aliases:</div>
                                <div class="val">LAND, JAMES FREDERICK;LAND, JAMES F;LAND, JAMES F;LAND, JAMES F;LAND, JAMES FREDRICK</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Changes:</div>
                                <div class="val">Home-12 WOODBINE RD, BILLERICA, MA 01821-2319</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Predator:</div>
                                <div class="val">false</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Absconder:</div>
                                <div class="val">false</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Last Updated:</div>
                                <div class="val">June 28, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Created:</div>
                                <div class="val">June 28, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Last Synced:</div>
                                <div class="val">November 19, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Synced:</div>
                                <div class="val">1</div>
                            </div>
                                            </div>
                </div>
            </div>
                        <div class="row acc ">
                <div class="head acc-head">
                    <div class="img">
                        <img class="lazyload" data-src="https://storage.googleapis.com/sex-offenders-images-v3/ILE99A1394.jpeg" alt="" width="96" height="96">
                    </div>
                    <div class="inf">
                        <div class="t1">RASHAD E GREAVES</div>
                        <div class="t2"><strong>Offense:</strong> 0996200 - AGGRAVATED CRIMINAL SEXUAL ASSAULT;0011344 - FAILURE TO REPORT CHANGE OF ADDRESS</div>
<!--                        <div class="t3">--><!--</div>-->
                    </div>
                    <div class="cta">
                        <span class="btn sm special">Full Report</span>
                    </div>
                </div>
                <div class="body acc-body">
                    <div class="r-table two-cols">
                                                    <div class="row">
                                <div class="ttl">Name:</div>
                                <div class="val">RASHAD E GREAVES</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Gender:</div>
                                <div class="val">Male</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Date of Birth:</div>
                                <div class="val">December 22, 1977</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Race:</div>
                                <div class="val">Black</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Height:</div>
                                <div class="val">5ft 10in</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Weight:</div>
                                <div class="val">168</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Eye:</div>
                                <div class="val">Unknown</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Hair:</div>
                                <div class="val">Unknown</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Age:</div>
                                <div class="val">46</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Scars Marks:</div>
                                <div class="val">No markings found.</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Address:</div>
                                <div class="val">JAIL MIDDLESEX CO, BILLERICA, MA 01821</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">State:</div>
                                <div class="val">MA</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Location:</div>
                                <div class="val">BILLERICA</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">ZipCode:</div>
                                <div class="val">01821</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Aliases:</div>
                                <div class="val">No known aliases</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Changes:</div>
                                <div class="val">Home-JAIL MIDDLESEX CO, BILLERICA, MA 01821</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Predator:</div>
                                <div class="val">false</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Absconder:</div>
                                <div class="val">false</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Last Updated:</div>
                                <div class="val">June 28, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Created:</div>
                                <div class="val">June 28, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Last Synced:</div>
                                <div class="val">November 19, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Synced:</div>
                                <div class="val">1</div>
                            </div>
                                            </div>
                </div>
            </div>
                        <div class="row acc ">
                <div class="head acc-head">
                    <div class="img">
                        <img class="lazyload" data-src="https://storage.googleapis.com/sex-offenders-images-v3/MA56066.jpeg" alt="" width="96" height="96">
                    </div>
                    <div class="inf">
                        <div class="t1">ROBERT E WAY</div>
                        <div class="t2"><strong>Offense:</strong> 3/19/2003-Indecent assault and battery on child under 14 years of age;3/19/2003-Open and gross lewdness and lascivious behavior;12/1/2000-Open and gross lewdness and lascivious behavior</div>
<!--                        <div class="t3">--><!--</div>-->
                    </div>
                    <div class="cta">
                        <span class="btn sm special">Full Report</span>
                    </div>
                </div>
                <div class="body acc-body">
                    <div class="r-table two-cols">
                                                    <div class="row">
                                <div class="ttl">Name:</div>
                                <div class="val">ROBERT E WAY</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Gender:</div>
                                <div class="val">Male</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Date of Birth:</div>
                                <div class="val">January 01, 1966</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Race:</div>
                                <div class="val">White</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Height:</div>
                                <div class="val">6ft 4in</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Weight:</div>
                                <div class="val">300</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Eye:</div>
                                <div class="val">Blue</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Hair:</div>
                                <div class="val">Brown</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Age:</div>
                                <div class="val">58</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Scars Marks:</div>
                                <div class="val">No markings found.</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Address:</div>
                                <div class="val">78 BILLERICA AVE, BILLERICA, MA 01821</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">State:</div>
                                <div class="val">MA</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Location:</div>
                                <div class="val">BILLERICA</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">ZipCode:</div>
                                <div class="val">01821</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Aliases:</div>
                                <div class="val">No known aliases</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Changes:</div>
                                <div class="val">Home-78 BILLERICA AVE, BILLERICA, MA 01821;Unknown-78 BILLERICA AVE, BILLERICA, MA 01862</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Predator:</div>
                                <div class="val">false</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Absconder:</div>
                                <div class="val">false</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Last Updated:</div>
                                <div class="val">June 28, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Created:</div>
                                <div class="val">June 28, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Last Synced:</div>
                                <div class="val">November 19, 2024</div>
                            </div>
                                                    <div class="row">
                                <div class="ttl">Is Synced:</div>
                                <div class="val">1</div>
                            </div>
                                            </div>
                </div>
            </div>
                    </div>
            </div>
    </div>

        
                                            </div>                    </div>
            </div>
        </main>
    </div>
<script type="text/javascript" src="/tspecV2/PremiumPropertyReport/shared/js/smoothscroll.js"></script>
<script type="text/javascript" src="/tspecV2/PremiumPropertyReport/shared/js/lazysizes.js"></script>
<script type="text/javascript" src="/tspecV2/PremiumPropertyReport/shared/js/app.js?v=1.1"></script>
<script type="text/javascript" src="https://code.highcharts.com/highcharts.js"></script>
<script type="text/javascript" src="https://code.highcharts.com/modules/exporting.js"></script>
<script type="text/javascript" src="https://code.highcharts.com/modules/accessibility.js"></script>
<script type="text/javascript" src="/tspecV2/PremiumPropertyReport/shared/js/highchartsOptionsColumn.js"></script>
</body>

<style>
    .nhm {
		width: 100%;
        margin: 30px 0;
        padding: 25px 30px;
        text-align: center;
        box-shadow: 0 0 15px 0 rgb(53 156 243 / 30%);
    }
	.nhm .ttl {
		font-size: 18px;
		font-weight: 700;
		line-height: 24px;
	}
</style>


<input type="hidden" id="ywgitw" name="ywgitw" value="pFFpNZmglCLJNAFtPmECVNFZcdxgS0vZjGzz7NEtJonKGz95oeOkcIxcSBpuD0UFmxNDpFEzZom5ILirqUZK2A==">

        <footer class="site-footer">
    <div class="wrapper">
        <div class="row">
            <div class="col">
                <div class="ttl">Menu</div>
                <ul>
                    <li><a href="/members">Home</a></li>
                                        <li><a href="/members/contact">Contact Us</a></li>
                    <li><a href="/coverage">Coverage</a></li>
                                    </ul>
                <ul class="acs-set">
                    <a class="fx" id="accessibilityWidget" tabindex="0" style="cursor: pointer;"><span class="ic icn-accsessibility"></span>Accessibility Settings</a>
                </ul>
            </div>

            <div class="col">
                <div class="ttl">Searches</div>
                <ul>
                    <li><a href="/owners">Owners</a></li>
                    <li><a href="/liens">Liens</a></li>
                    <li><a href="/deeds">Deeds</a></li>
                    <li><a href="/building-permits">Building Permits</a></li>
                </ul>
            </div>
            <div class="col">
                <div class="ttl">Legal</div>
                <ul>
                    <li><a href="/terms/">Terms of Service</a></li>
                    <li><a href="/privacy/">Privacy Policy</a></li>
                    <li><a href="/accessibility/">Accessibility Statement</a></li>
                    <li><a href="/optout/">Your Privacy Choices</a></li>
                    <li><a href="/optout/">Do Not Sell / Share My Personal Information</a></li>
                </ul>
            </div>
            <div class="col inf">
                <div class="site-logo  black"></div>
                <p>PropertyChecker is a premier property data platform offering powerful tools to individuals and businesses who need detailed property information at their fingertips.</p>                <ul class="cnt">
                    <li class="phn"><a href="tel: (800) 396-4909">(800) 396-4909</a></li>
                    <li class="eml">
                                                <a href="mailto:support@propertychecker.com">Email Us</a>                    </li>
                    <li class="adr">227 Lewis Wharf, Boston, MA 02110</li>
                </ul>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col cpy">
                <p>© 2024 PropertyChecker.com is a service of InfoPay, Inc. All rights reserved.</p>
            </div>
            <div class="col soc">
                <ul>
                    <li><a class="fb" href="https://www.facebook.com/PropertyCheckers/" target="_blank" title="Facebook">Facebook</a></li>
                    <li><a class="tw" href="https://x.com/PropertyChckr" target="_blank" title="X.com">X.com</a></li>
                    <li><a class="yt" href="https://www.youtube.com/@PropertyChecker" target="_blank" title="YouTube">YouTube</a></li>
                    <li><a class="li" href="https://www.linkedin.com/company/95029228" target="_blank" title="LinkedIn">LinkedIn</a></li>
                    <li><a class="pi" href="http://pinterest.com/PropertyCheckers/" target="_blank" title="Pinterest">Pinterest</a></li>
                </ul>
            </div>
        </div>
    </div>
</footer>
\

<!-- OneTrust Cookies Consent Notice start for propertychecker.com -->

<script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  
        type="text/javascript" 
        charset="UTF-8" 
        data-domain-script="32a2f197-7891-4a1a-a949-f68f07616b21" ></script>
<script type="text/javascript">
    function OptanonWrapper() { }
</script>
<!-- OneTrust Cookies Consent Notice end for propertychecker.com -->
        <script src="/js/ma/filters.js?v=1.2"></script>
<script src="/js/jquery.cookie.min.js"></script>
<script src="/js/jquery-ui.min.js"></script>
<script src="/js/modernizr.js"></script>
<script src="/js/jquery.slicknav.js"></script>
<script src="/js/slick.min.js"></script>
<script src="/js/smoothscroll.js"></script>
<script src="/js/lazysizes.js"></script>
<script src="/js/lightbox.js?v=1.1"></script>
<script src="/js/app.js?v=2.9.13"></script>
<script src="/js/menu.js?v=1.0"></script>
<script src="/packages/jquery.ui.1.13.2/jquery-ui.min.js"></script>
<script src="/js/address_autocomplete.js?v=1.7"></script>
<script src="/js/jQuery.ajaxQueue.js"></script>
<script>jQuery(function ($) {
    $(document).ready(function() {
        $('.address-autocomplete').each(function() {
            let uniqueId = $(this).attr('data-unique-id');
            // does add-unit need to be added?
            let addressUnit = $('#address-unit-' + uniqueId);
            let address = $('#address-' + uniqueId);
            if (!addressUnit.length && address.length) {
                let addedUnit = $(
                    '<div style="display:block; opacity:0; width:0px; height:0px; position: relative; top: -20px">'
                    + '<input type="text" id="address-unit" name="address-unit-b" /></div>'
                );
                address.after(addedUnit);
            }
            
            const InternalAddressAutoCplt = new AddressAutoCompleteByInternal(
                "/address/find?", "2", "#address-" + uniqueId, "#address-unit-" + uniqueId, "", "pFFpNZmglCLJNAFtPmECVNFZcdxgS0vZjGzz7NEtJonKGz95oeOkcIxcSBpuD0UFmxNDpFEzZom5ILirqUZK2A=="
            );
            InternalAddressAutoCplt.Initialize();
        })
        
    });

    $(document).ready(function() {
        const InternalAddressAutoCplt = new AddressAutoCompleteByInternal(
            "/address/find?", "2", "#addresssearchform-address-67647ecbfc0f0418403ff5dd", "#address-unit-67647ecbfc0f0418403ff5dd", "#address-json-67647ecbfc0f0418403ff5dd", "pFFpNZmglCLJNAFtPmECVNFZcdxgS0vZjGzz7NEtJonKGz95oeOkcIxcSBpuD0UFmxNDpFEzZom5ILirqUZK2A=="
        );
        InternalAddressAutoCplt.Initialize();
    });

setTimeout(() => {
    (function (d) {
    var s = d.createElement("script");
    s.setAttribute("data-account", "Q6kh6QqWbm");
    s.setAttribute("src", "https://cdn.userway.org/widget.js");
    (d.body || d.head).appendChild(s);
    })(document);
}, 5000);
});</script>        <div id="searchBlock" class="lbox-container">
    <div class="lbox-container-inn lg">
        <div class="lbox-body">
            <div class="search-error">
                <div class="img"><img src="/img/error/search_block_error.svg" alt=""></div>
                <div class="txt">
                    <p>Based on our review of your account, your current search history has triggered a review by our team for
                        potential violations of our <a href="/terms">Terms of Service</a> and/or applicable federal and
                        state laws. Based on our review, we have suspended your account.
                    </p>
                    <p>In order to reinstate your account,
                        please contact us at <a href="tel:(844) 777-0503">(844) 777-0503</a> or
                        <a href="mailto:privacy@propertychecker.com">privacy@propertychecker.com</a>
                        with an explanation of your use of the information provided by
                        your searches. Until we receive your response, your searches will be blocked
                    </p>
                </div>
                <div class="button">
                    <div class="row">
                        <div class="col">
                        <a class="btn md" href="javascript:void(0)" onclick="hideSearchBlockLightbox()">I Understand</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a class="lbox-close" href="javascript:void(0)" onclick="hideSearchBlockLightbox()" title="Close">Close</a>
    </div>
</div>        <div id="ofacBlock" class="lbox-container">
    <div class="lbox-container-inn lg">
        <div class="lbox-body">
            <div class="search-error">
                <div class="img"><img src="/img/error/search_block_error.svg" alt=""></div>
                <div class="txt">
                    <p>
                        Our team has identified a potential compliance issue with your account that may not align with our <a href="/terms">Terms of Service</a>.
                        As a result, your account has been temporarily suspended.
                    </p>
                    <p>
                        For assistance with reactivating your account, please contact us at <a href="tel:(844) 777-0503">(844) 777-0503</a> or <a href="mailto:privacy@propertychecker.com">privacy@propertychecker.com</a>.
                        Please note that while the suspension is in effect, some account features will remain disabled.
                    </p>
                </div>
                <div class="button">
                    <div class="row">
                        <div class="col">
                        <a class="btn md" href="javascript:void(0)" onclick="hideOfacBlockLightbox()">I Understand</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a class="lbox-close" href="javascript:void(0)" onclick="hideOfacBlockLightbox()" title="Close">Close</a>
    </div>
</div>    </body>
    </html>
`