import PropTypes from "prop-types";
import React from "react";
import { useGauge } from "use-gauge";

import styles from  './ArcedChart.module.scss';


const START_ANGLE = 100;
const END_ANGLE = 260;

export function Arced({value, chartType}) {

    let color = '#1b1bec';

    const gauge = useGauge({
        domain: [0, 100],
        startAngle: START_ANGLE,
        endAngle: END_ANGLE,
        numTicks: 21,
        diameter: 130,
    });

    return (
        <div className={styles.p_4}>
            <div className={styles.total}>{value}</div>
            <svg className={styles.w_full} {...gauge.getSVGProps({})}>
                <g id="arcs">
                    <path
                        {...gauge.getArcProps({
                            offset: 0,
                            startAngle: START_ANGLE,
                            endAngle: END_ANGLE,
                        })}
                        stroke={'#B1B1C2'}
                        fill="none"
                        className={styles.stroke_gray_200}
                        strokeLinecap='butt'
                        strokeWidth={34}
                        strokeOpacity={.5}
                    />
                    <path
                        {...gauge.getArcProps({
                            offset: 0,
                            startAngle: START_ANGLE,
                            endAngle: gauge.valueToAngle(value)

                        })}
                        fill="none"
                        // stroke={props.value <= 50 ? '#EE2828' : props.value < 85 ? '#FFCF27' : '#19EAAA'}
                        stroke={color}
                        strokeLinecap='butt'
                        strokeWidth={34}
                    />
                </g>
            </svg>
        </div>
    );
}

Arced.propTypes = {
    value: PropTypes.number,
    chartType: PropTypes.string,
};