import { PlusCircle } from '@phosphor-icons/react';
import PropTypes from 'prop-types'
import { useEffect, useState, useRef } from "react";
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import styles from './index.module.scss';

import { ReportAPI } from '@/api/report';
import logo from '@/assets/headerLogo.svg';
import hourglass from "@/assets/icons/hourglass.svg";
import leftAngleBlackIcon from '@/assets/icons/leftAngleBlack.svg';
import rightAngleBlackIcon from '@/assets/icons/rightAngleBlack.svg';
import { BILIRICA_TEMPLATE } from '@/assets/templates/bilirica-template';
import { WALTHAM_TEMPLATE } from '@/assets/templates/waltham-template';
import { Arced } from '@/components/ArcedChart/ArcedChart';
import Card, { CardType, CardLinkPosition } from '@/components/Card'
import { ChatComponent } from '@/components/ChatComponent/chatComponent';
import { UploadModal } from '@/components/InfoPayModule/UploadModal';
import { Col, Row, Icon, Contents, Img, Button, Input } from '@/components/Layout';
import { selectUserData } from '@/store/user/selectors';
import { issue_category } from '@/views/PropertyInsights/Issues';
import issueStyles from '@/views/PropertyInsights/Issues.module.scss';

export const AgeCard = ({ info, index, openChat = null }) => {
    return (
        <Card
            key={index}
            className={`w-100 align-items-start text-start`}
            type={CardType.smallBox}
        // linkPosition={openChat ? CardLinkPosition.topRight : null}
        // action={openChat ? () => {
        //     openChat();
        // } : null}
        >
            <Card.Header icon={hourglass} style={{ fontSize: '18px' }}>Property Age</Card.Header>
            <Card.Body>
                <h4 className='fw-bold'>{info?.category.charAt(0).toUpperCase() + info?.category.slice(1)}</h4>
                <p className='mt-4' style={{ fontSize: '14px', height: '120px', maxHeight: '120px', overflowY: 'auto' }}>{
                    info?.description}
                </p>
            </Card.Body>
        </Card>
    )
}

AgeCard.propTypes = {
    info: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    openChat: PropTypes.func.isRequired
}

export const KeyQuestions = ({ info, index, openChat = null }) => {
    return (
        <Card
            key={index}
            className={`w-100 align-items-start text-start`}
            type={CardType.smallBox}
        // linkPosition={openChat ? CardLinkPosition.topRight : null}
        // action={openChat ? () => {
        //     openChat();
        // } : null}
        >
            <Card.Header icon={hourglass} style={{ fontSize: '18px' }}>Key Questions</Card.Header>
            <Card.Body>
                <h4 className='fw-bold'>{info?.category.charAt(0).toUpperCase() + info?.category.slice(1)}</h4>
                <ul
                    className="mt-4"
                    style={{
                        fontSize: '14px',
                        height: '120px',
                        maxHeight: '120px',
                        overflowY: 'auto',
                        listStyleType: 'disc', /* Ensures bullet points */
                        paddingLeft: '20px'   /* Adds spacing for the bullets */
                    }}
                >
                    {info?.questions?.map((question, index) => (
                        <li key={index}>{question.question}</li>
                    ))}
                </ul>
            </Card.Body>
        </Card>
    )
}

KeyQuestions.propTypes = {
    info: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    openChat: PropTypes.func.isRequired
}

export const PermitsAnalysis = ({ info, index, openChat = null }) => {
    return (
        <Card
            key={index}
            className={`w-100 align-items-start text-start`}
            type={CardType.smallBox}
        // linkPosition={openChat ? CardLinkPosition.topRight : null}
        // action={openChat ? () => {
        //     openChat();
        // } : null}
        >
            <Card.Header icon={hourglass} style={{ fontSize: '18px' }}>Permit Analysis</Card.Header>
            <Card.Body>
                <h4 className='fw-bold'>{info?.permit_type}</h4>
                <p className='mt-2' style={{ fontSize: '14px' }}><b>Permit date</b>: {info?.permit_date}</p>
                <p className='mt-2' style={{ fontSize: '14px', height: '120px', maxHeight: '120px', overflowY: 'auto' }}>{
                    info?.importance}
                </p>
            </Card.Body>
        </Card>
    )
}

PermitsAnalysis.propTypes = {
    info: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    openChat: PropTypes.func.isRequired
}

export const IssueCard = ({ issue, index, openChat = null }) => {
    const [hover, setHover] = useState({
        id: -1,
        isHovered: false
    });

    return (
        <Card key={index}
            className={'text-start observed-element'}
            type={CardType.smallBox}
            linkPosition={openChat ? CardLinkPosition.topRight : null}
            action={openChat ? () => {
                openChat();
            } : null}
            onMouseEnter={openChat ? () => {
                setHover({
                    id: index,
                    isHovered: true
                })
            } : null}
            onMouseLeave={openChat ? () => {
                setHover({
                    id: -1,
                    isHovered: false
                })
            } : null}>
            <Card.Header className={`${styles.card_header} py-1 gap-2`}>
                <div className={styles.icon_wrapper}>
                    {issue_category[issue.category]?.icon}
                </div>
                <p style={{ fontSize: '18px' }}>
                    {((hover.isHovered && index === hover.id)) && issue.issue.length >= 13 ? issue.issue.slice(0, 12) + '...' : issue.issue}
                </p>
            </Card.Header>

            <Card.Body className={'pt-2 h-100'}>
                <div className={styles.price_container}>
                    <div className={`${issueStyles.price}`}>
                        ${issue.cost_estimate_low}-{issue.cost_estimate_high}
                    </div>
                </div>
                <div className={`${styles.card_body_description} mt-2 overflow-auto`}>
                    {issue.context}
                </div>
                <div className={`${styles.page_container} justify-content-between align-items-end`}>
                    {/*<div className={commonStyles.price + ' ' + priceColor}>{issue.source}</div>*/}
                    <div>{issue.source}</div>
                    <Img src={issue_category[issue.category]?.image} />
                </div>
            </Card.Body>
        </Card>
    )
}

IssueCard.propTypes = {
    issue: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    openChat: PropTypes.func.isRequired
}

export const QualityConditionCard = ({ info, index, openChat = null }) => {
    return (
        <Card
            key={index}
            className={`w-100 align-items-start text-start`}
            type={CardType.smallBox}
        // linkPosition={openChat ? CardLinkPosition.topRight : null}
        // action={openChat ? () => {
        //     openChat();
        // } : null}
        >
            <Card.Header style={{ fontSize: '18px' }}>Quality & Condition</Card.Header>
            <Card.Body>
                <div className="d-flex flex-column align-items-center w-100">
                    {/* Use the Arced component instead of custom gauge */}
                    <div className="position-relative mb-3" style={{ width: '150px' }}>
                        <Arced
                            value={info.score}
                            chartType="quality"
                        />
                    </div>

                    {/* Condition Text */}
                    <div className="text-center mb-3">
                        <span style={{ fontWeight: 'bold' }}>
                            {info.condition}
                        </span>
                    </div>

                    {/* Description */}
                    <p style={{ fontSize: '14px', maxHeight: '70px', overflowY: 'auto' }}>
                        {info.description}
                    </p>
                </div>
            </Card.Body>
        </Card>
    )
}

QualityConditionCard.propTypes = {
    info: PropTypes.shape({
        score: PropTypes.number.isRequired,
        condition: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired
    }).isRequired,
    index: PropTypes.number.isRequired,
    openChat: PropTypes.func
}

export const InfoPayModule = ({ }) => {
    const [issues, setIssues] = useState(null);
    const [ageInfo, setAgeInfo] = useState(null);
    const [keyQuestions, setKeyQuestions] = useState(null);
    const [permitAnalysis, setPermitAnalysis] = useState(null);
    const [chat, setChat] = useState(false);
    const [chatTopic, setChatTopic] = useState(null);
    const chatContainerRef = useRef(null);
    const [isWaitingForBotResponse, setIsWaitingForBotResponse] = useState(false);
    const [message, setMessage] = useState('');
    const initialBotMessage = "Hey there! I am the HomeScore Assistant! I'm here to help answer your questions "
    const [chatMessages, setChatMessages] = useState([]);
    const [chatTaskId, setChatTaskId] = useState("");
    const [chatId, setChatId] = useState(0);
    const user = useSelector(selectUserData);
    const [loading, setLoading] = useState('.');
    const [hasReport, setHasReport] = useState(false);
    const [reports, setReports] = useState([]);
    const [uploadOpen, setUploadOpen] = useState(false);
    const [gotChecker, setGotChecker] = useState(null);
    const [token, setToken] = useState("");
    const [isValidToken, setIsValidToken] = useState(false);
    const [shouldPoll, setShouldPoll] = useState(false);
    const [currentReport, setCurrentReport] = useState({
        checker_id: 12342,
        template: BILIRICA_TEMPLATE,
        name: 'Report 1'
    });
    const [qualityConditions, setQualityConditions] = useState([]);

    // const reports = {
    //     report1: {
    //         checker_id: 12342,
    //         template: BILIRICA_TEMPLATE,
    //         name: 'Report 1'
    //     },
    //     report2: {
    //         checker_id: 12343,
    //         template: WALTHAM_TEMPLATE,
    //         name: 'Report 2'
    //     }
    // };

    const getChecker = async (report = currentReport) => {
        if (token !== "") {
            try {
                let response = await ReportAPI.getPropertyChecker(token, report.checker_id);
                if (response.data.data === null) {
                    setGotChecker(false);

                    const blob = new Blob([report.template], { type: "text/html" });
                    const file = new File([blob], "myfile.html", { type: "text/html" });

                    const formData = new FormData();
                    formData.append("file", file);
                    formData.append("checker_id", report.checker_id);

                    await ReportAPI.initPropertyChecker(token, formData);
                    response = await ReportAPI.getPropertyChecker(token, report.checker_id);
                }

                const data = response.data.data;
                setAgeInfo(data.age_risk);
                setKeyQuestions(data.property_questions);
                setPermitAnalysis(data.permits_analysis);
                if (data.home_quality) {
                    setQualityConditions([data.home_quality]);
                } else {
                    setQualityConditions([]);
                }

                if (data.has_inspection && data.upload_status === "Completed") {
                    setHasReport(true);
                    setIssues(data.property_issues)
                    setShouldPoll(false);
                } else if (data.has_inspection) {
                    setHasReport(true)
                } else {
                    setHasReport(false)
                }

                if (!data.has_inspection) {
                    setShouldPoll(false);
                }

                setIsValidToken(true)
                setGotChecker(true);
            } catch (error) {
                toast.error(error.response.data.detail)
                setShouldPoll(false);
            }
        }
    }

    const handleChange = (e, event) => {
        setToken(event.target.value);
    }

    const handleSubmit = async () => {
        setShouldPoll(true);
        try {
            const response = await ReportAPI.getPropertyCheckerList(token);
            const list = [];
            if (Array.isArray(response.data.data)) {
                response.data.data.forEach(checker => {
                    list.push({
                        checker_id: checker,
                        name: "Report " + checker
                    });
                });

                setReports(list);

                if (list.length > 0) {
                    setCurrentReport(list[0]);
                    await getChecker(list[0]);
                } else {
                    toast.warning("No reports found.");
                }
            } else {
                toast.error("Unexpected response format.");
            }
        } catch (error) {
            toast.error("Failed to fetch report list: " + error.message);
        }
    }

    const handleChat = async (issue, isChat, report_id) => {
        setChat(isChat);
        setChatTopic(issue);
        setChatId(report_id);
        setChatMessages([{
            content: initialBotMessage + `about ${issue}. How can I help?`,
            message_type: "AI",
            issue: issue
        }]);
    }

    const handleMessage = (e, event) => {
        event.preventDefault();
        setMessage(event.target.value);
    }

    const handleMessageSend = async () => {
        if (message !== "" || message !== null || message !== undefined) {
            const newUserMessage = { content: message, message_type: "HUMAN", issue: chatTopic };
            setChatMessages([...chatMessages, newUserMessage]);
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
            await ReportAPI.sendCheckerMessage(token, chatId, message, chatTopic).then(data => {
                setChatTaskId(data.task_id)
                setMessage("")
                setIsWaitingForBotResponse(true);
            })
        }
    }

    async function handleKeyDown(event) {
        if (event.key === 'Enter') {
            const newUserMessage = { content: message, message_type: "HUMAN", issue: chatTopic };
            setChatMessages([...chatMessages, newUserMessage]);
            await ReportAPI.sendCheckerMessage(token, chatId, message, chatTopic).then(data => {
                setChatTaskId(data.task_id)
                setMessage("")
                setIsWaitingForBotResponse(true);
            })
        }
    }

    useEffect(() => {
        let interval;
        if (isWaitingForBotResponse) {
            interval = setInterval(() => {
                setLoading((prevLoad) => {
                    // If there are already 3 dots, reset to one dot.
                    return prevLoad === '...' ? '.' : prevLoad + '.';
                });
                chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                ReportAPI.getCheckerMessage(token, chatId, chatTaskId).then(data => {
                    if (data.state === "SUCCESS" || data.state === "FAIL") {
                        const newBotMessage = { content: data.result.content, message_type: "AI", issue: chatTopic };
                        setChatMessages([...chatMessages, newBotMessage]);
                        setIsWaitingForBotResponse(false);
                        clearInterval(interval);
                    }
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isWaitingForBotResponse, chatTaskId, chatMessages]);


    useEffect(() => {
        const chatContainer = document.getElementById("chatmessage");
        if (chatContainer) {
            chatContainer.scrollTop = chatContainer.scrollHeight;
        }
    }, [chatMessages, chat]);

    useEffect(() => {
        let interval;
        if (shouldPoll) {
            interval = setInterval(() => {
                getChecker();
            }, 10000);
        }

        return () => clearInterval(interval);
    }, [shouldPoll]);

    const handleReportChange = async (reportKey) => {
        const newReport = reports.find(report => report.checker_id === reportKey);
        setCurrentReport(newReport);
        setIssues(null);
        setGotChecker(false);
        setShouldPoll(true);

        // Introduce a delay of 2 seconds before calling getChecker
        await new Promise(resolve => setTimeout(resolve, 1000));

        // Now make the API call with the updated report
        await getChecker(newReport);
    };

    return (
        <Col
            className={styles.contents + ' container-fluid text-center w-100 mt-auto align-items-center pt-5'}
            style={{ height: "auto", minHeight: '43rem', backgroundColor: 'lightgrey' }}
        >
            <UploadModal isOpen={uploadOpen} onClose={() => { setUploadOpen(false); setShouldPoll(true); getChecker() }} auth_token={token} checkerId={currentReport.checker_id} />
            <Contents>
                {
                    chat ?
                        (
                            <>
                                <Row className='w-100 mb-5' style={{ justifyContent: 'space-around' }}>
                                    <div className={"px-2 py-1"} style={{ width: '40rem', backgroundColor: 'white', borderRadius: '40px' }}>
                                        <ChatComponent
                                            title={chatTopic?.charAt(0).toUpperCase() + chatTopic?.slice(1)}
                                            chat={chat}
                                            setChat={setChat}
                                            chatContainerRef={chatContainerRef}
                                            isWaitingForBotResponse={isWaitingForBotResponse}
                                            message={message}
                                            loading={loading}
                                            chatMessages={chatMessages}
                                            handleMessage={handleMessage}
                                            handleKeyDown={handleKeyDown}
                                            handleMessageSend={handleMessageSend}
                                        />
                                    </div>
                                    <div style={{ width: '20rem' }}>
                                        {ageInfo?.find(info => info.category === chatTopic) && (
                                            <AgeCard
                                                info={ageInfo.find(info => info.category === chatTopic)}
                                                index={0}
                                            />
                                        )}
                                        {keyQuestions?.find(q => q.category === chatTopic) && (
                                            <KeyQuestions
                                                info={keyQuestions.find(q => q.category === chatTopic)}
                                                index={0}
                                            />
                                        )}
                                        {permitAnalysis?.find(p => p.permit_type === chatTopic) && (
                                            <PermitsAnalysis
                                                info={permitAnalysis.find(p => p.permit_type === chatTopic)}
                                                index={0}
                                            />
                                        )}
                                        {issues?.find(i => i.issue === chatTopic) && (
                                            <IssueCard
                                                issue={issues.find(i => i.issue === chatTopic)}
                                                index={0}
                                            />
                                        )}
                                    </div>
                                </Row>
                            </>
                        )
                        :
                        (
                            <>
                                <Row style={{ marginBottom: '5rem' }}>
                                    <Col>
                                        <Img style={{ height: '3rem' }} src={logo} />
                                        <p className="mt-2">Explore property risks and key questions. Upload photos or a home inspection for deeper insights.</p>
                                    </Col>
                                    <Row className={'flex-nowrap gap-3'}>
                                        {
                                            (token && token !== "") && isValidToken && gotChecker ?
                                                (
                                                    <>
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                                                {currentReport.name}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                {reports.map((report) => (
                                                                    <Dropdown.Item
                                                                        key={report.checker_id}
                                                                        onClick={() => handleReportChange(report.checker_id)}
                                                                        active={currentReport.checker_id === report.checker_id}
                                                                    >
                                                                        {report.name}
                                                                    </Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        {
                                                            !hasReport && (
                                                                <Button type="button" style={{ width: '11.5rem' }} onClick={() => { setUploadOpen(true) }}>
                                                                    <PlusCircle size={32} />
                                                                    Home Inspection
                                                                </Button>
                                                            )
                                                        }
                                                        {
                                                            hasReport && shouldPoll && (
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <p>Loading...</p>
                                                                    <div className="spinner-border" role="status">
                                                                        <span className="visually-hidden">Loading...</span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        <>
                                                            <Icon src={leftAngleBlackIcon} size={36} className={'swiper-button-prev-unique cursor-pointer'} />
                                                            <Icon src={rightAngleBlackIcon} size={36} className={'swiper-button-next-unique cursor-pointer'} />
                                                        </>
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <Input className={styles.input} type="password" value={token} name="token" placeholder={"Authorization Token"} onChange={handleChange} />
                                                        <Button onClick={handleSubmit}>Submit</Button>
                                                    </>
                                                )
                                        }
                                    </Row>
                                </Row>
                                {
                                    gotChecker ?
                                        (
                                            <>
                                                <>
                                                    <Swiper modules={[Navigation]}
                                                        navigation={{
                                                            nextEl: '.swiper-button-next-unique',
                                                            prevEl: '.swiper-button-prev-unique'
                                                        }}
                                                        autoHeight={true}
                                                        style={{ height: '21rem' }}
                                                        speed={600}
                                                        className={'w-100'}
                                                        breakpoints={{
                                                            [1300]: {
                                                                spaceBetween: 16,
                                                                slidesPerView: 4,
                                                                slidesPerGroup: 4,
                                                            },
                                                            [992]: {
                                                                spaceBetween: 16,
                                                                slidesPerView: 3,
                                                                slidesPerGroup: 3,
                                                            },
                                                            [768]: {
                                                                spaceBetween: 16,
                                                                slidesPerView: 2,
                                                                slidesPerGroup: 2,
                                                            },
                                                            [525]: {
                                                                spaceBetween: 10,
                                                                slidesPerView: 1,
                                                                slidesPerGroup: 1,
                                                            },
                                                        }}
                                                    >
                                                        {ageInfo?.map((risk, index) => (
                                                            <SwiperSlide key={index}>
                                                                <AgeCard info={risk} index={index} openChat={() => handleChat(risk.category, true, currentReport.checker_id)} />
                                                            </SwiperSlide>
                                                        ))}
                                                        {keyQuestions?.map((questions, index) => (
                                                            <SwiperSlide key={index}>
                                                                <KeyQuestions info={questions} index={index} openChat={() => handleChat(questions.category, true, currentReport.checker_id)} />
                                                            </SwiperSlide>
                                                        ))}
                                                        {permitAnalysis?.map((permit, index) => (
                                                            <SwiperSlide key={index}>
                                                                <PermitsAnalysis info={permit} index={index} openChat={() => handleChat(permit.permit_type, true, currentReport.checker_id)} />
                                                            </SwiperSlide>
                                                        ))}
                                                        {qualityConditions?.map((condition, index) => (
                                                            <SwiperSlide key={index}>
                                                                <QualityConditionCard
                                                                    info={condition}
                                                                    index={index}
                                                                    openChat={() => handleChat(condition.category, true, currentReport.checker_id)}
                                                                />
                                                            </SwiperSlide>
                                                        ))}
                                                        {issues?.map((issue, index) => (
                                                            <SwiperSlide key={index}>
                                                                <IssueCard issue={issue} index={index} openChat={() => handleChat(issue?.issue, true, currentReport.checker_id)} />
                                                            </SwiperSlide>
                                                        ))}
                                                    </Swiper>
                                                </>
                                            </>
                                        )
                                        :
                                        (
                                            gotChecker === null ?
                                                (
                                                    <>
                                                    </>
                                                )
                                                :
                                                (
                                                    <h1>
                                                        Loading HomeScore Property Cards
                                                    </h1>
                                                )
                                        )
                                }
                            </>
                        )
                }
            </Contents>

        </Col>
    )
}