import PropTypes from 'prop-types';
import { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { toast } from 'react-toastify';

import { ReportAPI } from '@/api/report';
import styles from '@/components/HouseCard/Upload.module.scss';
import { Button, Col, Modal } from '@/components/Layout';

export const UploadModal = ({ isOpen, onClose, auth_token, checkerId }) => {
    const [files, setFiles] = useState([]);
    const [progress, setProgress] = useState(0);
    const [enabled, setEnabled] = useState(true);
    const [abortController, setAbortController] = useState(null);

    const handleFileChange = (file) => {
        setFiles([file]);
    };

    const onUploadProgress = (progressEvent) => {
        setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
    };

    const sendFile = () => {
        if (files.length > 0) {
            if (abortController) {
                abortController.abort();
                setAbortController(null);
            }
            const controller = new AbortController();
            setAbortController(controller);
            setEnabled(false);

            try {
                ReportAPI.postInspectionRecord(auth_token, checkerId, files[0], { onUploadProgress, signal: controller.signal });
                toast.success("File uploaded successfully!");
                setFiles([]);
                setProgress(0);
                setTimeout(() => {
                    onClose();
                }, 2000);
            } catch (error) {
                toast.error("File upload failed.");
            } finally {
                setEnabled(true);
            }
        } else {
            toast.warning("Please add a file to upload.");
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} title="Upload File">
            <Col className={'w-100 align-items-center'} style={{ maxWidth: '440px' }}>
                <FileUploader
                    handleChange={handleFileChange}
                    classes={`${styles.fileUploader} d-flex flex-column align-items-center cursor-pointer mt-4 mb-2`}
                    types={['pdf', 'jpg', 'png']}
                >
                    <h3>Drop your files here</h3>
                    <p><u>Browse</u> file from your computer</p>
                </FileUploader>

                {files.length > 0 && (
                    <div className={styles.fileProgress}>
                        <span>{files[0].name}</span>
                        <div className={styles.progress}>
                            <div className={styles.bar}>
                                <div style={{ width: `${progress}%` }} />
                            </div>
                            <span>{progress}%</span>
                        </div>
                    </div>
                )}

                <Button disabled={!enabled} className={`${styles.continueBtn} mt-4`} onClick={sendFile}>
                    {enabled ? 'Upload' : 'Uploading...'}
                </Button>
            </Col>
        </Modal>
    );
};

UploadModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    auth_token: PropTypes.string.isRequired,
    checkerId: PropTypes.any.isRequired,
}; 